import React from 'react'
import axios from 'axios'
import Header from '../../components/Header'
import Footer from '../../components/Footer'
import { Link } from 'react-router-dom'
import '../../css/common.css'
import '../../css/user.css'
import '../../css/order.css'
export default class Comment extends React.Component {
    constructor(props) {
        super(props);
        this.state = { commodityStars: 0, commodity: {}, commodityAjacCompleted: false };
    }

    componentDidMount() {
        this.getCommodityRequest();
    }

    getCommodityRequest() {
        let commodityId = this.props.match.params.commodityId;
        const apiUrl = `http://www.liaofushen.xyz/commodity/${commodityId}`;
        axios.get(apiUrl).then((response) => {
            let data = response.data;
            this.setState({ commodity: data.data.commodity, commodityAjacCompleted: true });
            console.log(data.data);
        }).catch((error) => {
            console.log(error);
        });
    }

    setCommodityStars(stars) {
        this.setState({ commodityStars: stars });
    }

    postCommentRequest(desc) {
        if (desc === '') {
            alert('评价不能为空。');
            return;
        }
        let { commodityStars } = this.state;
        if (commodityStars === 0) {
            alert('请选择评价星级。');
            return;
        }
        let commodityId = this.props.match.params.commodityId;
        let orderId = this.props.match.params.orderId;
        let requestJson = {
            commodityId: commodityId,
            desc: desc,
            starLevel: commodityStars,
            orderId: orderId
        };
        axios.post(`http://www.liaofushen.xyz/comment`, requestJson).then((response) => {
            let data = response.data;
            console.log(data);
            if (data.status === 0) {
                alert('评论成功，即将返回订单页面。');
                window.location.href = `/#/user/myOrder`;
            }
            else {
                alert('评论失败');
            }
        }).catch((error) => {
            console.log(error);
        });
    }

    render() {
        let { commodityStars, commodity } = this.state;
        let descTextarea;
        return (
            <div id='content'>
                <Header />
                <div className='order-head'>
                    <div className='w'>
                        <div className='order-head-content'>
                            <div className='order-head-content-logo'>
                                <Link to='/'>
                                    <img alt='分期商城' style={{ height: '100%', width: '100%' }} src='http://liaofushen.xyz:8081/logo.png' />
                                </Link>
                            </div>
                            <div className='order-head-content-words'>商品评价</div>
                        </div>
                    </div>
                </div>
                <div className='w'>
                    <div className='comment-commodity-info-box'>
                        <img className='comment-commodity-info-img' alt='图片' src={ commodity.image } />
                        <div className='comment-commodity-info-title'>{ commodity.title }</div>
                    </div>
                    <div className='comment-box'>
                        <div style={{ margin: '50px 0px' }} className='comment-stars'>
                            <span className='comment-head'>评分</span>
                            <ul className='comment-stars-ul'>
                                <li onClick={ () => this.setCommodityStars(1) }>{ commodityStars < 1? '☆': '★' }</li>
                                <li onClick={ () => this.setCommodityStars(2) }>{ commodityStars < 2? '☆': '★' }</li>
                                <li onClick={ () => this.setCommodityStars(3) }>{ commodityStars < 3? '☆': '★' }</li>
                                <li onClick={ () => this.setCommodityStars(4) }>{ commodityStars < 4? '☆': '★' }</li>
                                <li onClick={ () => this.setCommodityStars(5) }>{ commodityStars < 5? '☆': '★' }</li>
                            </ul>
                        </div>
                        <div>
                            <span className='comment-head'>评价</span>
                            <textarea ref={ node => descTextarea = node } className='comment-desc'></textarea>
                        </div>
                        <div className='comment-btn' onClick={ () => this.postCommentRequest(descTextarea.value) }>提交评价</div>
                    </div>
                </div>
                <Footer />
            </div>
        )
    }
}
import React from 'react'
import { connect } from 'react-redux'
import { staffChattingWith, readMsg } from '../../actions/webSocketActions'
import { sendMsgGetRead } from '../../websocket-client'
import '../../css/cs.css'

class ContactBox extends React.Component {
    render() {
        let { chatState, csState, staffChattingWith } = this.props;
        let { chattingWith } = csState; 
        return (
            <div className='staffContactBox'>
                { 
                    Object.keys(chatState).map((key) => 
                        <div 
                            key={ key }
                            className="contactBox-Item"
                            onClick={ staffChattingWith.bind(this, key, chatState[key].unreadCount, chattingWith) }
                        >
                            { chatState[key].userInfo.userId }
                            
                            { 
                                chatState[key].unreadCount === 0 
                                ? '' 
                                : <div className="sign-new-msg">{ chatState[key].unreadCount.toString() }</div> 
                            }
                            
                        </div>
                    ) 
                }
            </div>
        );
    }
}

const mapStateToProps = state => ({
    chatState: state.chatState,
    csState: state.csState
});

const mapDispatchToProps = dispatch => ({
    staffChattingWith: (targetId, unreadCount, chattingWith) => { 
        if (chattingWith === targetId)
            return;
        if (unreadCount !== 0) {
            sendMsgGetRead(targetId);
            dispatch(readMsg(targetId));
        }
        dispatch(staffChattingWith(targetId));
    }
});

export default connect(mapStateToProps, mapDispatchToProps)(ContactBox)
import React from 'react'
import axios from 'axios'
import SingelRecommendBox from './SingelRecommendBox'
import '../../css/common.css'
import '../../css/search.css'
import '../../css/recommend.css'
import '../../css/home.css'
export default class CartRecommendBox extends React.Component {
    constructor(props) {
        super(props);
        this.state = { recommendList: [] };
    }

    componentDidMount() {
        this.getCommodityRecommendIncartRequest();
    }

    changeShowingIndex(index) {
        this.setState({ showingIndex: index });
    }

    getCommodityRecommendIncartRequest() {
        axios.get('http://www.liaofushen.xyz/commodity/recommend/incart').then((response) => {
            let data = response.data;
            console.log(data);
            if (data.status === 0) {
                let recommendData = data.data;
                recommendData.map((item) => {
                    let commodities = item.recommendList;
                    let commoditiesList = [];
                    const groupCount = 5;
                    let cListLength = commodities.length;
                    for(let i = 0; i < cListLength; i += groupCount) {
                        commoditiesList.push(commodities.splice(0, groupCount));
                    }
                    item.recommendList = commoditiesList;
                    return '';
                });
                this.setState({ recommendList: data.data });
            }
        }).catch((error) => {
            console.log(error);
        });
    }

    render() {
        let { recommendList } = this.state;
        return (
            <div style={{ width: '1000px' }}>
                {
                    recommendList.map((item, index) => {
                        return (<SingelRecommendBox commoditiesList={ item.recommendList } recommendReason={item.recommendReason} key={index}  />);
                    })
                }
            </div>
        )
    }
}
import React from 'react'
import Footer from '../../components/Footer'
import '../../css/common.css'
import '../../css/register.css'
export default class Register extends React.Component {
    constructor(props) {
        super(props);
        this.state = { loginMessage: '', loginError: false };
    }

    postAdminLoginRequest(username, password) {
        if (!username) {
            this.setState((state) => {
                return { loginMessage: '请输入账号', loginError: true };
            });
        }
        else if (!password) {
            this.setState((state) => {
                return { loginMessage: '请输入密码', loginError: true };
            });
        }
        else if (username && password && username === 'admin' && password === 'admin') {
            this.props.adminLoginSuccess();
        }
        else {
            this.setState((state) => {
                return { loginMessage: '账号或密码输入错误', loginError: true };
            });
        }
    }

    render() {
        let { loginError, loginMessage } = this.state;
        let usernameInput, passwordInput;
        return (
            <div id='content'>
                <div className='register-head'>
                    <div className='w'>
                        <div className='register-head-content'>
                            <div className='register-head-content-logo'>
                                <img alt='分期商城' style={{ height: '100%', width: '100%' }} src='http://liaofushen.xyz:8081/logo.png' />
                            </div>
                            <div className='register-head-content-words'>管理员登陆</div>
                        </div>
                    </div>
                </div>
                <div className='w'>
                    <div className='register-form'>
                        <div className='form-item'>
                            <div className='form-title'>管理员登陆</div>
                        </div>
                        <div className='form-item'>
                            <span className='item-head'>账号</span>
                            <span className='item-content'>
                                <input ref={ node => usernameInput = node } className='form-input' placeholder='在此输入账号' type='text' />
                            </span>
                        </div>
                        <div className='form-item'>
                            <span className='item-head'>密码</span>
                            <span className='item-content'>
                                <input ref={ node => passwordInput = node } className='form-input' placeholder='在此输入密码' type='password' />
                            </span>
                        </div>
                        {
                            loginError ? <div className='form-item'><div className='register-form-error'>{ loginMessage }</div></div> : ''
                        }
                        <div className='form-item'>
                            <button 
                                className='form-register-btn' 
                                onClick={ () => {
                                    this.postAdminLoginRequest(usernameInput.value, passwordInput.value)
                                }}
                            >登陆</button>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        );
    }
}
import React from 'react'
import { connect } from 'react-redux'
import '../../css/common.css'
import axios from 'axios';
class Information extends React.Component { 
    postUserUpdateRequest(username, sex, email, phone) {
        let requestJson = {
            username: username,
            sex: sex,
            email: email,
            phone: phone
        };
        axios.post('http://www.liaofushen.xyz/user/update', requestJson).then((response) => {
            console.log(response.data);
            let data = response.data;
            if (data.status === 0) {
                alert('修改成功');
            }
        }).catch((error) => {
            console.log(error);
        })
    }
    
    render() {
        let { userState } = this.props;
        let { username, email, sex, phone } = userState;
        let usernameInput, emailInput, phoneInput;
        console.log(userState);
        return (
            <div className='user-info-box'>
                <div className='user-box-head'><div className='user-box-head-icon'></div>个人资料</div>
                <div className='info-box-item'>
                    <div className='info-box-item-head'>昵称：</div>
                    <div className='info-box-item-content'>
                        <input className='info-box-item-input' ref={ node => usernameInput = node } type='text' defaultValue={ username } />
                    </div>
                </div>
                <div className='info-box-item'>
                    <div className='info-box-item-head'>手机号码：</div>
                    <div className='info-box-item-content'>
                        <input className='info-box-item-input' ref={ node => phoneInput = node } type='text' defaultValue={ phone ? phone: '' } />
                    </div>
                </div>
                <div className='info-box-item'>
                    <div className='info-box-item-head'>电子邮箱：</div>
                    <div className='info-box-item-content'>
                        <input className='info-box-item-input' ref={ node => emailInput = node } type='text' defaultValue={ email ? email: '' } />
                    </div>
                </div>
                <div className='info-box-item'>
                    <div className='info-box-item-head'>性别：</div>
                    <div className='info-box-item-content'>
                        <input name='info-sex' value='1' type='radio' defaultChecked={ sex ? 'defaultChecked': '' } />男
                        <input name='info-sex' value='0' type='radio' defaultChecked={ sex ? '': 'defaultChecked' } />女
                    </div>
                </div>
                <div className='info-box-split-line'></div>
                <div className='info-box-save-btn' onClick={ () => this.postUserUpdateRequest(
                    usernameInput.value, document.getElementsByName('info-sex')[0].checked === true? true: false,
                    emailInput.value, phoneInput.value
                ) }>保存</div>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    userState: state.userState
});

const mapDispatchToProps = dispatch => ({
    
});

export default connect(mapStateToProps, mapDispatchToProps)(Information);
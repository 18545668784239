import React from 'react'
import { Link } from 'react-router-dom'
import '../../css/home.css'
import '../../css/common.css'
export default class CategoryBox extends React.Component {
    render() {
        let { keywordList } = this.props;
        return (
            <div className='category-content'>
                {
                    Object.keys(keywordList).map((item, index) => {
                        return (
                            <div className='category-box'  key={ index }>
                                <h3 style={{ marginBottom: '10px' }}>{ item }</h3>
                                <hr style={{ borderWidth: '2px' }} />
                                {
                                    keywordList[item].map((item1) => {
                                        return (
                                            <div className='category-box-item' key={ item1.id }>
                                                <Link to={ `/search?keyword=${item1.name}` }>
                                                <p>{ item1.name }</p>
                                                <img alt={ item1.name } className='category-box-item-img' src={ item1.img } /></Link>
                                            </div>
                                        );
                                    })
                                }
                            </div>
                        );
                    })
                }
            </div>
        );
    }
}
import { sendPictureInfo, sendPictureFragment, sendPictureEnd } from '../websocket-client'
import { getStoreDispatch, getStoreState } from '../store'
import * as types from '../constants/ActionTypes'

const fragmentSize = 2048;

let uploadList = {};
let readyList = {};
let fileIdFlag = 0;
export function addUploadPictureItem(fileInfo, targetId, senderId) {
    let type = fileInfo.name.split('.')[1];
    let reader = new FileReader();
    reader.readAsDataURL(fileInfo);
    reader.onload = function () {
        let dataUrl = this.result.split(',')[1];
        let size = dataUrl.length;
        readyList[fileIdFlag] = { type, size, dataUrl, targetId, senderId, imgDataUrl: this.result };
        sendPictureInfo(targetId, size, type, fileIdFlag);
        fileIdFlag++;
    }
}

export function sendPictureInfoSuccess(fileName, fileId) {
    let { targetId, senderId } = readyList[fileId];
    let { chatState } = getStoreState();
    let { imgDataUrl } = readyList[fileId];
    let msgId = chatState[targetId].msgCount;
    uploadList[fileName] = {
        ...readyList[fileId], current: 0, msgId
    }
    delete readyList[fileId];
    nextPictureFragment(fileName);
    getStoreDispatch()({ type: types.SEND_PICTURE_INFO_SUCCESS, targetId, senderId, dataUrl: imgDataUrl, msgId });
}

export function nextPictureFragment(fileName) {
    let { current, size, dataUrl } = uploadList[fileName];
    let pictureFragment;
    if (current < size) {
        pictureFragment = dataUrl.slice(current, current + fragmentSize);
        current += fragmentSize;
        //send next
        sendPictureFragment(fileName, pictureFragment);
        uploadList[fileName] = { ...uploadList[fileName], current };
    }
    else {
        //send end
        sendPictureEnd(fileName);
    }
}

export function uploadPictureEnd(fileName) {
    let { targetId, msgId } = uploadList[fileName];
    getStoreDispatch()({ type: types.SEND_PICTURE_END_SUCCESS, targetId, msgId });
    delete uploadList[fileName];
}
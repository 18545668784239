import React from 'react'
import axios from 'axios'
import { connect } from 'react-redux'
import { userLoginSuccess } from '../../actions/userActions'
import Footer from '../../components/Footer'
import { Link } from 'react-router-dom'
import '../../css/logIn.css'
import '../../css/common.css'

class Login extends React.Component {
    constructor(props) {
        super(props);
        this.state = { loginRequesting: false, loginError: false, loginMessage: '' };
    }

    postLoginRequest(username, password) {
        this.setState({ loginRequesting: true });
        axios.post('http://www.liaofushen.xyz/user/login', { username, password }).then(function(response) {
            console.log(response.data);
            let data = response.data;
            //this.props.userLoginSuccess(data.id, data.username);
            if (data.status === 0) {
                let reg = new RegExp('(^|&)returnUrl=([^&]*)(&|$)', 'i');
                let search = window.location.href.split('?');
                search = search[search.length - 1];
                if (reg.test(search)) {
                    let returnUrl = decodeURI(search.match(reg)[2]);
                    window.location.href = returnUrl;
                    return;
                }
                window.location.href = '/';
            }
            else {
                this.setState({ loginError: true, loginMessage: data.message, loginRequesting: false });
            }
        }.bind(this)).catch(function(error) {
            this.setState({ loginError: true, loginMessage: '登陆发生错误', loginRequesting: false });
            console.log(error);
        }.bind(this));
    }

    render () {
        let usernameInput;
        let passwordInput;
        let { loginRequesting, loginMessage } = this.state;
        return (
            <div>
                <div className='login-head'>
                    <div className='w'>
                        <div className='login-head-content'>
                            <div className='login-head-content-logo'>
                                <Link to='/'>
                                    <img alt='分期商城' style={{ height: '100%', width: '100%' }} src='http://liaofushen.xyz:8081/logo.png' />
                                </Link>
                            </div>
                            <div className='login-head-content-words'>欢迎登陆</div>
                        </div>
                    </div>
                </div>
                <div id='content'>
                        <div className='login-wrapper'>
                        <div className='w'>
                            <div className='login-form'>
                                <div className='login-form-item'>
                                    <div className='login-form-title'>登陆</div>
                                </div>
                                <div className='login-form-item'>
                                    <div className='login-item-head'>账号</div>
                                    <div className='login-item-content'>
                                        <input className='login-form-input' placeholder='在此处输入账号' ref={node => usernameInput = node} type='text' />
                                    </div>
                                </div>
                                <div className='login-form-item'>
                                    <div className='login-item-head'>密码</div>
                                    <div className='login-item-content'>
                                        <input className='login-form-input' placeholder='在此处输入密码' ref={node => passwordInput = node} type='password' />
                                    </div>
                                </div>
                                <div className='login-form-item'>
                                    <div className='form-forget-password'>忘记密码</div>
                                    <Link to={ `/register` }><div className='form-free-register'>免费注册</div></Link>
                                </div>
                                <div className='login-form-item'>
                                    <div className='login-form-error'>{ loginMessage }</div>
                                </div>
                                <div className='login-form-item'>
                                    <button className='form-login-btn' onClick={() => {
                                        if (loginRequesting)
                                            return;
                                        this.postLoginRequest(usernameInput.value, passwordInput.value);
                                    }}>登陆</button>
                                </div>
                            </div>
                            <div className='login-banner' style={{backgroundColor: 'gray'}}>
                                <div className='w'>
                                    <div className='banner-bg' style={{backgroundColor: 'gray'}}></div>
                                </div>
                            </div>
                        </div>
                        </div>
                </div>
                <Footer />
            </div>
        );
    };
}

const mapStateToProps = state => ({
    
});

const mapDispatchToProps = dispatch => ({
    userLoginSuccess: (userId, userName) => dispatch(userLoginSuccess(userId, userName))
});

export default connect(mapStateToProps, mapDispatchToProps)(Login)
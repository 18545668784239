import React from 'react'
import axios from 'axios'
import { Link } from 'react-router-dom'
import '../../css/common.css'
import '../../css/user.css'

const orderItemsStatus = {
    '0': '待支付',
    '1': '待发货',
    '2': '待收货',
    '3': '待评价',
    '4': '已完成'
};
const orderItemsControl = {
    '0': [{ text: '前往支付', getUrl: (flag) => `/pay/${flag}` }],
    '1': [],
    '2': [{ text: '确认收货', flag: null, getUrl: (flag) => `/takeDelivery/${flag}` }],
    '3': [{ text: '前往评价', flag: null, getUrl: (flag0, flag1) => `/comment/${flag0}/${flag1}` }],
    '4': []
};

export default class MyOrder extends React.Component {
    constructor(props) {
        super(props);
        this.state = { orderList: [], ajaxCompleted: false, tabIndex: -1, showInstallmentDetail: false, installmentDetail: {} };
    }

    componentDidMount() {
        this.getOrderRequest();
    }

    setShowInstallmentDetail(isShow, installmentDetail) {
        this.setState({ showInstallmentDetail: isShow, installmentDetail: installmentDetail });
    }

    getOrderRequest() {
        axios.get('http://www.liaofushen.xyz/order').then((response) => {
            console.log(response.data);
            let data = response.data;
            if (data.status === 0) {
                this.setState({ orderList: data.data, ajaxCompleted: true });
            }
        }).catch((error) => {
            console.log(error);
        });
    }

    changeTabIndex(value) {
        value = parseInt(value);
        this.setState({ tabIndex: value });
    }

    render() {
        let { ajaxCompleted, orderList, tabIndex, showInstallmentDetail, installmentDetail } = this.state;
        if (!ajaxCompleted) {
            return (<div>订单拉取中...</div>);
        }
        return (
            <div className='myOrder-box'>
                {
                    showInstallmentDetail? <div className='myOrder-i-d-box'>
                        <div onClick={ () => this.setShowInstallmentDetail(false, {}) } className='myOrder-i-d-quit'></div>
                        <div className='myOrder-i-d-content'>
                            <div className='myOrder-i-d-c-head'>还款详细</div>
                            <div className='myOrder-i-d-c-content'>
                                {
                                    Array.apply(null, Array(installmentDetail.installment)).map((item, index) => {
                                        return (
                                            <div className='myOrder-i-d-c-c-item' key={ index }>
                                                <div className='myOrder-i-d-c-c-i-left'>
                                                    <div className='myOrder-i-d-c-c-i-top'>
                                                        { `${index + 1}/${installmentDetail.installment} 期` }
                                                    </div>
                                                    <div className='myOrder-i-d-c-c-i-bottom'>
                                                        { installmentDetail.installmentHavePaid > index? '已还款': '未还款' }
                                                    </div>
                                                </div>
                                                <div className='myOrder-i-d-c-c-i-right'>{ `￥${installmentDetail.perPrice}` }</div>
                                            </div>
                                        );
                                    })
                                }
                                <Link to={ `/pay/${installmentDetail.orderId}` }><div className='myOrder-i-d-c-c-btn'>还款</div></Link>
                            </div>
                        </div>
                    </div>: ''
                }
                <div className='user-box-head'><div className='user-box-head-icon'></div>我的订单</div>
                <div className='myOrder-tab-box'>
                    <div className={ `myOrder-tab-box-item ${tabIndex === -1? 'myOrder-tab-box-item-checking': ''}` } 
                        onClick={ () => this.changeTabIndex(-1) }>所有订单</div>
                    <div className={ `myOrder-tab-box-item ${tabIndex === 0? 'myOrder-tab-box-item-checking': ''}` } 
                        onClick={ () => this.changeTabIndex(0) }>待付款</div>
                    <div className={ `myOrder-tab-box-item ${tabIndex === 1? 'myOrder-tab-box-item-checking': ''}` } 
                        onClick={ () => this.changeTabIndex(1) }>待发货</div>
                    <div className={ `myOrder-tab-box-item ${tabIndex === 2? 'myOrder-tab-box-item-checking': ''}` } 
                        onClick={ () => this.changeTabIndex(2) }>待收货</div>
                    <div className={ `myOrder-tab-box-item ${tabIndex === 3? 'myOrder-tab-box-item-checking': ''}` } 
                        onClick={ () => this.changeTabIndex(3) }>待评价</div>
                </div>
                <div className='myOrder-head'>
                    <div style={{ width: '295px' }}>商品</div>
                    <div style={{ width: '100px' }}>单价</div>
                    <div style={{ width: '50px' }}>数量</div>
                    <div style={{ width: '100px' }}>交易状态</div>
                    <div style={{ width: '100px' }}>交易操作</div>
                </div>
                <div className='myOrder-content'>
                    {
                        orderList.filter((item) => { 
                            if (tabIndex === -1) {
                                return true;
                            }
                            return item.status === tabIndex? true: false; 
                        }).length === 0? <div style={{ margin: '50px 30px' }}>该项目下没有订单</div>: ''
                    }
                    {
                        orderList.filter((item) => { 
                            if (tabIndex === -1) {
                                return true;
                            }
                            return item.status === tabIndex? true: false; 
                        }).map((item, index) => {
                            return (
                                <div className='myOrder-item' key={ index }>
                                    <div className='myOrder-item-head'>
                                        <span style={{ marginLeft: '15px' }}><b>{ `${item.created}` }</b></span>
                                        <span style={{ marginLeft: '15px' }}>{ `订单号：${item.id}` }</span>
                                        <span style={{ marginLeft: '15px' }}>{ `价钱共计：￥${item.priceSum}` }</span>
                                    </div>
                                    <div>
                                        { 
                                            item.orderItems.map((item1, index1) => {
                                                return (
                                                    <div className='myOrder-commodity-item' key={ index1 }>
                                                        <div className='myOrder-commodity-info'>
                                                            <div className='myOrder-commodity-img'>
                                                                <img 
                                                                    style={{ width: '100%', height: '100%' }} 
                                                                    src={ item1.commodityImg } 
                                                                    alt={ item1.commodityTitle } 
                                                                />
                                                            </div>
                                                            <Link to={ `/commodity/${item1.commodityId}` }>
                                                                <div className='myOrder-commodity-title'>
                                                                    { item1.commodityTitle }
                                                                </div>
                                                            </Link>
                                                        </div>
                                                        <div className='myOrder-commodity-price'>
                                                            { `￥${item1.commodityPrice}` }
                                                        </div>
                                                        <div className='myOrder-commodity-num'>
                                                            { item1.commodityNum }
                                                        </div>
                                                        <div className='myOrder-commodity-status'>
                                                            { orderItemsStatus[item.status] }
                                                            {
                                                                item.installment !== 1 && item.installmentHavePaid > 0?
                                                                <p onClick={ () => this.setShowInstallmentDetail(true, {
                                                                    installment: item.installment,
                                                                    installmentHavePaid: item.installmentHavePaid,
                                                                    perPrice: (item.priceSum / item.installment).toFixed(2),
                                                                    orderId: item.id
                                                                }) } className='myOrder-commodity-status-i-d'>还款详情</p>: ''
                                                            }
                                                        </div>
                                                        <div className='myOrder-commodity-control'>
                                                            {
                                                                item.installment !== 1 && index1 === 0? 
                                                                item.installmentHavePaid !== 0 && item.installment > item.installmentHavePaid?
                                                                <p><Link to={ `/pay/${item.id}` }>还款</Link></p>: '': ''
                                                            }
                                                            {
                                                                orderItemsControl[item.status].map((item2, index2) => {
                                                                    if (item.status === 3) {
                                                                        return (
                                                                            <p key={ index2 }>
                                                                                <Link to={ item2.getUrl(item1.commodityId, item.id) }>
                                                                                    { item2.text }
                                                                                </Link>
                                                                            </p>
                                                                        );
                                                                    }
                                                                    if (item.status !== 3 && index1 > 0) {
                                                                        return '';
                                                                    }
                                                                    return (
                                                                        <p key={ index2 }>
                                                                            <Link to={ item2.getUrl(item.id) }>{ item2.text }</Link>
                                                                        </p>
                                                                    );
                                                                })
                                                            }
                                                        </div>
                                                    </div>
                                                );
                                            })
                                        }
                                    </div>
                                </div>
                            );
                        })
                    }
                </div>
            </div>
        );
    }
}
import React from 'react'
import axios from 'axios'
import '../../css/common.css'
import '../../css/admin.css'
export default class OrderManage extends React.Component {
    constructor(props) {
        super(props);
        this.state = { ordersData: [], checkingIndex: 0 };
    }

    componentDidMount() {
        let { checkingIndex } = this.state;
        switch (checkingIndex) {
            case 0: this.getManagerOrderRequest(); break;
            default: break;
        }
    }

    postManagerOrderSendRequest(orderId) {
        axios.post(`http://liaofushen.xyz:8080/manager/order/send/${orderId}`).then((response) => {
            let data = response.data;
            console.log(data);
            if (data.status === 0) {
                this.getManagerOrderRequest();
            }
        }).catch((error) => {
            console.log(error);
        });
    }

    postManagerOrderCancelRequest(orderId) {
        axios.post(`http://liaofushen.xyz:8080/manager/order/cancel/${orderId}`).then((response) => {
            let data = response.data;
            console.log(data);
            if (data.status === 0) {
                this.getManagerOrderRequest();
            }
        }).catch((error) => {
            console.log(error);
        });
    }

    getManagerOrderRequest() {
        axios.get('http://liaofushen.xyz:8080/manager/order?status=1').then((response) => {
            let data = response.data;
            console.log(data);
            if (data.status === 0) {
                let ordersData = data.data;
                this.setState({ ordersData: ordersData });
            }
        })
    }

    render() {
        let { ordersData, checkingIndex } = this.state;
        let omContent = {
            0: (
                <div className='o-m-unfinished-order-content'>
                    {
                        ordersData.length > 0? ordersData.map((item, index) => {
                            return (
                                <div className='o-m-u-o-item' key={ index }>
                                    <div className='o-m-u-o-i-head'>
                                        <span style={{ width: '100px' }}>订单编号</span>
                                        <span style={{ width: '300px' }}>创建日期</span>
                                        <span style={{ width: '150px' }}>操作</span>
                                    </div>
                                    <div className='o-m-u-o-i-content'>
                                        <div className='o-m-u-o-i-id'>{ item.id }</div>
                                        <div className='o-m-u-o-i-created'>{ item.created }</div>
                                        <div className='o-m-u-o-i-control'>
                                            <div onClick={ () => this.postManagerOrderSendRequest(item.id) }>发货</div>
                                            <div onClick={ () => this.postManagerOrderCancelRequest(item.id) }>取消订单</div>
                                        </div>
                                    </div>
                                </div>
                            );
                        }): '数据拉取中'
                    }
                </div>
            )
        };
        return (
            <div className='o-m-box'>
                <div className='o-m-left'>
                    <ul className='o-m-left-ul'>
                        <li className={ `${checkingIndex === 0? 'o-m-left-ul-li-checking': ''}` }>未发货订单</li>
                    </ul>
                </div>
                <div className='o-m-right'>
                    <div className='o-m-right-head'>
                        <div className='o-m-right-head-icon'></div>
                        未发货订单
                    </div>
                    {
                        omContent[checkingIndex]
                    }
                </div>
            </div>
        );
    }
}
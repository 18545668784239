import React from 'react'
import axios from 'axios'
import { Link } from 'react-router-dom'
import Footer from '../../components/Footer'
import Header from '../../components/Header'
import CartRecommendBox from '../../components/CartRecommendBox'
import '../../css/common.css'
import '../../css/cart.css'
export default class Cart extends React.Component {
    constructor(props) {
        super(props);
        this.state = { cartText: '数据拉取中', cartChanging: false, cartData: [], cartEmpty: true, lastCartData: [] };
    }

    getCartRequest() {
        axios.get('http://www.liaofushen.xyz/cart').then(function(response) {
            console.log(response.data);
            let data = response.data;
            let cartData = data.data;
            if (data.status === 0) {
                let cartEmpty = cartData.length === 0 ? true: false;
                this.setState({ cartEmpty, cartData: cartData, cartCount: cartData.length, lastCartData: cartData });
            }
            else {
                this.setState({ cartText: data.message, cartEmpty: true });
            }
        }.bind(this)).catch(function(error) {
            this.setState({ cartText: '加载购物车出错', cartEmpty: true });
            console.log(error);
        }.bind(this));
    }

    deleteCartRequest(id) {
        this.setState({ cartChanging: true });
        axios.delete(`http://www.liaofushen.xyz/cart/${id}`).then((response) => {
            let data = response.data;
            let cartData = data.data;
            if (data.status === 0) {
                let cartEmpty = cartData.length === 0 ? true: false;
                this.setState({ cartEmpty, cartData: cartData, cartCount: cartData.length, lastCartData: cartData, cartChanging: false });
            }
            else {
                this.setState({ cartText: data.message, cartEmpty: true, cartChanging: false });
            }
        }).catch((error) => {
            this.setState({ cartText: '加载购物车出错', cartEmpty: true, cartChanging: false });
            console.log(error);
        });
    }

    putCartRequest(id, num) {
        axios.put('http://www.liaofushen.xyz/cart', { id, num }).then((response) => {
            console.log(response.data);
            let data = response.data;
            if (data.status === 0) {
                this.setState({ cartChanging: false, lastCartData: this.state.cartData });
            }
            else {
                this.setState({ cartChanging: false, cartData: this.state.lastCartData });
            }
        }).catch((error) => {
            console.log(error);
        })
    }

    buyCountChange(buyCount, index) {
        let { cartData, lastCartData } = this.state;
        buyCount = buyCount + '';
        buyCount = buyCount.replace(/[^0-9]/ig, '');
        if (!buyCount) {
            buyCount = 1;
        }
        buyCount = parseInt(buyCount);
        if (buyCount === cartData[index].num) {
            return;
        }
        if (buyCount === 0) {
            buyCount = 1;
        }
        let num = 200;
        if (buyCount > num) {
            buyCount = num;
        }
        lastCartData = cartData;
        cartData[index].num = buyCount;
        let cartId = cartData[index].id;
        let cartNum = buyCount;
        this.setState({ cartData, cartChanging: true, lastCartData });
        this.putCartRequest(cartId, cartNum);
    }
    
    componentDidMount() {
        this.getCartRequest();
    }

    render() {
        let { cartText, cartChanging, cartData, cartEmpty } = this.state;
        
        return (
            <div id='content'>
                <Header />
                <div className='cart-head'>
                    <div className='w'>
                        <div className='cart-head-content'>
                            <div className='cart-head-content-logo'>
                                <Link to='/'>
                                    <img alt='分期商城' style={{ height: '100%', width: '100%' }} src='http://liaofushen.xyz:8081/logo.png' />
                                </Link>
                            </div>
                            <div className='cart-head-content-words'>购物车</div>
                        </div>
                    </div>
                </div>
                <div className='w'>
                    <div className='cart-box'>
                        {
                            cartEmpty ? <div className='cart-notice-text'>{ cartText }</div>: 
                            <div className='cart-box-head'>
                                <span style={{ marginLeft: '50px' }}>商品</span>
                                <span style={{ marginLeft: '520px' }}>单价</span>
                                <span style={{ marginLeft: '80px' }}>数量</span>
                                <span style={{ marginLeft: '90px' }}>总计</span>
                                <span style={{ marginLeft: '90px' }}>操作</span>
                            </div>
                        }
                        {
                            cartData.map((item, index) => {
                                let buyCount = item.num;
                                let buyCountInput;
                                return (
                                    <div className='cart-item' key={ index }>
                                        <div className='cart-cell cart-item-img'>
                                            <img alt={ item.title } style={{ width: '100%', height: '100%' }} src={ item.image } />
                                        </div><Link to={ `/commodity/${item.id}` }>
                                        <div className='cart-cell cart-item-title'>
                                            { item.title }
                                        </div></Link>
                                        <div className='cart-cell cart-item-params'>
                                            { `颜色：${item.colour}` } <br />
                                            { `尺寸：${item.size}` }
                                        </div>
                                        <div className='cart-cell cart-item-price'>
                                            { `￥${item.price}` }
                                        </div>
                                        <div className='cart-cell cart-item-num'>
                                            <div className='buyCount-box'>
                                                <div className='buyCount-count'>
                                                    <input 
                                                        ref={ node => buyCountInput = node } 
                                                        className='buyCount-count-input' type='text' value={ buyCount } onChange={ () => {
                                                            if (!cartChanging) {
                                                                this.buyCountChange(buyCountInput.value, index);
                                                            }
                                                    }} />
                                                </div>
                                                <div className='buyCount-operation'>
                                                    <div className={ `buyCount-add ${ buyCount < 200 ? '': 'unclickable' }` } onClick={ () => {
                                                        if (buyCount < 200 && !cartChanging) {
                                                            this.buyCountChange(++buyCount, index);
                                                        }
                                                    }} >+</div>
                                                    <div className={ `buyCount-subtract ${ buyCount > 1 ? '': 'unclickable'}` } onClick={ () => {
                                                        if (buyCount > 1 && !cartChanging) {
                                                            this.buyCountChange(--buyCount, index);
                                                        }
                                                    }}>-</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='cart-cell cart-item-total'>
                                            { `￥${item.num * item.price}` }
                                        </div>
                                        <div className='cart-cell cart-item-btns'>
                                            <div 
                                                className='cart-item-btn-delete'
                                                onClick={ () => this.deleteCartRequest(item.id) }
                                            >删除</div>
                                        </div>
                                    </div>
                                );
                            })
                        }
                        {
                            cartEmpty ? '' : <div className='cart-box-foot'>
                                <Link to={ `/order` } className='cart-box-foot-btn'>结算</Link>
                                <span className='cart-box-foot-total'>
                                    <span className='cart-box-foot-total-head'>总价：</span>
                                    <span className='cart-box-foot-total-content'>
                                        { `￥${cartData.reduce((total, item)=>{ return total + item.num * item.price }, 0)}` }
                                    </span>
                                </span>
                            </div>
                        }
                    </div>
                </div>
                <div className='w'><CartRecommendBox /></div>
                <div className='w'>
                    <Footer />
                </div>
            </div>
        );
    }
}
import React from 'react'
import DeskTop from './DeskTop'
import '../../css/cs.css'

export default class CustomerService extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            deskTopVisible: false
        }
    }

    onClick(e) {
        this.setState({
            deskTopVisible: !this.state.deskTopVisible
        })
    }

    render () {
        let deskTopVisible = this.state.deskTopVisible;
        return (
            <div className='customerService'>
                <div className='cs-showBtn'  onClick={ this.onClick.bind(this) }>
                { deskTopVisible ? '隐藏': '客服' }
                </div>
                <DeskTop deskTopVisible={ deskTopVisible } />
            </div>
        );
    }
}
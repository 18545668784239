import { webSocketReadyStateChange, receiveMsg } from '../actions/webSocketActions'
import { getStoreState, getStoreDispatch } from '../store'
import { sendPictureInfoSuccess, uploadPictureEnd, nextPictureFragment } from '../Utils/uploadPicture'
import { 
    downloadNextPictureFragment, 
    downloadPictureStartSuccess, 
    downloadPictureEnd 
} from '../Utils/downloadPicture';
import * as types from '../constants/ActionTypes'

const websocketUrl = 'ws://140.82.3.11:1234';
//const websocketUrl = 'ws://localhost:1234';
let ws;
//let wsConnecting = false;
let dispatch;

export function startWebSocket(isStaff) {
    console.log('ws cteating...');
    if (ws && ws.readyState === 1) {
        console.log('ws already created...');
        return;
    }
    ws = new WebSocket(websocketUrl);
    ws.binaryType = "arraybuffer";
    dispatch = getStoreDispatch();
    //dispatch(webSocketReadyState(this.readyState));
    ws.onopen = function () {
        console.log('ws opened...');
        //wsConnecting = true;
        dispatch(webSocketReadyStateChange(this.readyState));
        isStaff ? sendStaffWSLogIn() : sendUserWSLogIn();
        console.log('send info suc...');
    };
    ws.onmessage = function (evt) {
        if (typeof (evt.data) === "string") {
            console.log(evt.data);
            //dispatch(JSON.parse(evt.data));
            webSocketDispatch(JSON.parse(evt.data));
        }
        else {
            // Save, WebSocket-HTML5Player
        }
    };
    ws.onerror = function (evt) {
        console.log(JSON.stringify(evt));
        dispatch(webSocketReadyStateChange(this.readyState));
    };
    ws.onclose = function () {
        //wsConnecting = false;
        dispatch(webSocketReadyStateChange('2'));
    };
}

export function closeWebSocket() {
    ws.close();
    //wsConnecting = false;
}

function webSocketDispatch(action) {
    switch (action.type){
        case types.RECEIVE_MSG:
            let { csState } = getStoreState();
            let { chattingWith } = csState;
            dispatch(receiveMsg(action, chattingWith));
            break;
        case types.SEND_PICTURE_INFO_SUCCESS:
            sendPictureInfoSuccess(action.fileName, action.fileId);
            break;
        case types.SEND_PICTURE_FRAGMENT_SUCCESS:
            nextPictureFragment(action.fileName);
            break;
        case types.SEND_PICTURE_END_SUCCESS:
            uploadPictureEnd(action.fileName);
            break;
        case types.DOWNLOAD_PICTURE_START_SUCCESS:
            downloadPictureStartSuccess(action.fileName, action.dataUrlHead, action.downloadId);
            break;
        case types.DOWNLOAD_PICTURE_FRAGMENT_SUCCESS:
            downloadNextPictureFragment(action.fragment, action.downloadId);
            break;
        case types.DOWNLOAD_PICTURE_END:
            downloadPictureEnd(action.downloadId);
            break;
        default:
            dispatch(action);
    }
}

export function sendDataToServer(data) {
    ws.send(data);
}

export function sendUserRequestCS(questionText) {
    ws.send(JSON.stringify({ type: 'USER_REQUEST_CS', questionText: questionText }));
}

export function sendUserWSLogIn() {
    let { userState } = getStoreState();
    if (userState.userId != null) {
        ws.send(JSON.stringify({ type: 'USER_WS_LOG_IN', ...userState }));
    }
}

export function sendUserCancelRequestCS() {
    ws.send(JSON.stringify({ type: 'USER_CANCEL_REQUEST_CS'}));
}

//Staff
export function sendStaffWSLogIn() {
    let { csStaffInfo } = getStoreState();
    if (csStaffInfo.staffId != null) {
        ws.send(JSON.stringify({ type: 'STAFF_WS_LOG_IN', ...csStaffInfo}));
    }
}

export function sendStaffAcceptCustomer() {
    ws.send(JSON.stringify({ type: 'STAFF_ACCEPT_CUSTOMER' }));
}

export function sendStaffCloseChat(targetId) {
    ws.send(JSON.stringify({ type: 'STAFF_CLOSE_CHAT', targetId}));
}

//Common
export function sendUserSendMsg(targetId, msg, msgId) {
    ws.send(JSON.stringify({ type: 'USER_SEND_MSG', targetId, msg, msgId }));
}

export function sendStaffSendMsg(targetId, msg, msgId) {
    ws.send(JSON.stringify({ type: 'STAFF_SEND_MSG', targetId, msg, msgId }));
}

export function sendMsgGetRead(targetId) {
    ws.send(JSON.stringify({ type: types.MSG_GET_READ, targetId }));
}

//
export function sendPictureInfo(targetId, size, fileType, fileId) {
    ws.send(JSON.stringify({ type: types.SEND_PICTURE_INFO, targetId, size, fileType, fileId }));
}

export function sendPictureFragment(fileName, fragment) {
    ws.send(JSON.stringify({ type: types.SEND_PICTURE_FRAGMENT, fileName, fragment }));
}

export function sendPictureEnd(fileName) {
    ws.send(JSON.stringify({ type: types.SEND_PICTURE_END, fileName }));
}

//
export function downloadPictureStart(fileName) {
    ws.send(JSON.stringify({ type: types.DOWNLOAD_PICTURE_START, fileName }));
}

export function downloadPictureFragment(downloadId) {
    ws.send(JSON.stringify({ type: types.DOWNLOAD_PICTURE_FRAGMENT, downloadId }));
}
import React from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { userSetData } from '../actions/userActions'
import axios from 'axios'
import '../css/header-css.css'
import '../css/common.css'

import CustomerService from '../components/CustomerService'

class Header extends React.Component { 
    getUserRequest() {
        axios.get('http://www.liaofushen.xyz/user/').then((response) => {
            console.log(response.data);
            console.log(this.props.userState);
            let data = response.data;
            if (data.status === 0 && data.data) {
                if (data.data != null) {
                    console.log('header 18: ', data.data);
                    let userData = data.data;
                    this.props.userSetData(userData);
                }
            }
        }).catch((error) => {
            console.log(error);
        });
    }

    componentDidMount() {
        this.getUserRequest();
    }

    postUserLogoutRequest() {
        axios.post('http://www.liaofushen.xyz/user/logout').then((response) => {
            console.log(response.data);
            let data = response.data;
            if (data.status === 0) {
                window.location.reload();
            }
        }).catch((error) => {
            console.log(error);
        });
    }

    render() { 
        let { userState } = this.props;
        let userDropDownDiv;
        console.log(userState);
        return (
            <header className='header'>
                <div className='w'>
                    <nav className='navigation'>
                        <ul>
                            <li className='navigation-li-right'><Link to={ `/cart` }>我的购物车</Link></li>
                            <li className='spacer navigation-li-right'></li>
                            {
                                userState.loggedIn ?
                                '': <li className='navigation-li-right'>
                                    <Link to={ `/register?returnUrl=${encodeURI(window.location.href)}` }>免费注册</Link>
                                </li>
                            }
                            { 
                                userState.loggedIn ? 
                                <li className='navigation-li-right'>
                                    <div className='header-user'
                                        onMouseOver={ () => userDropDownDiv.classList.remove('hide') }
                                        onMouseLeave={ () => userDropDownDiv.classList.add('hide') }
                                    >
                                        <Link to={ `/user/information` }>{ `${userState.userName}` }</Link>
                                        <i style={{ marginLeft: '10px' }} className='icon-font'>v</i>
                                        <div className='header-dropdown hide' ref={ node => userDropDownDiv = node }>
                                            <div className='header-dropdown-item'>
                                                <Link to={ `/cart` }>我的购物车</Link>
                                            </div>
                                            <div className='header-dropdown-item'>
                                                <Link to={ `/user/myOrder` }>我的订单</Link>
                                            </div>
                                            <div className='header-dropdown-item' onClick={ () => this.postUserLogoutRequest() }>
                                                退出登陆
                                            </div>
                                        </div>
                                    </div>
                                </li>: 
                                <li className='navigation-li-right'>
                                    <Link to={ `/login?returnUrl=${encodeURI(window.location.href)}` }>您好，请登录</Link>
                                </li> 
                            }
                            <li className='navigation-li-left'><Link to={ `/` }>分期商城首页</Link></li>
                        </ul>
                    </nav>
                </div>
                <CustomerService />
                {/*<CustomerService />*/}
            </header>
        );
    }
}

const mapStateToProps = state => ({
    userState: state.userState
});

const mapDispatchToProps = dispatch => ({
    userSetData: (userData) => dispatch(userSetData(userData))
});

export default connect(mapStateToProps, mapDispatchToProps)(Header);
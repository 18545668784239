import React from 'react'
import Login from '../page/login'
import CSStaff from '../page/csstaff'
import Home from '../page/home'
import Search from '../page/search'
import Commodity from '../page/commodity'
import Register from '../page/register'
import Cart from '../page/cart'
import User from '../page/user'
import Order from '../page/order'
import Pay from '../page/pay'
import Admin from '../page/admin'
import TakeDelivery from '../page/TakeDelivery'
import Comment from '../page/comment'
import InsterestOrder from '../page/insterestOrder'
import Category from '../page/category'
import { Switch, Route } from 'react-router-dom';

const App = () => (
    <Switch>
        <Route exact path='/' component={ Home } />
        <Route path='/login' component={ Login } />
        <Route path='/register' component={ Register } />
        <Route path='/csstaff' component={ CSStaff } />
        <Route path='/search' component={ Search } />
        <Route path='/commodity/:commodityId' component={ Commodity } />
        <Route path='/cart' component={ Cart } />
        <Route path='/user' component={ User } />
        <Route path='/order' component={ Order } />
        <Route path='/pay/:orderId' component={ Pay } />
        <Route path='/admin' component={ Admin } />
        <Route path='/takeDelivery/:orderId' component={ TakeDelivery } />
        <Route path='/comment/:commodityId/:orderId' component={ Comment } />
        <Route path='/insterestOrder/:commodityId/:buyCount/:installmentIndex' component={ InsterestOrder } />
        <Route path='/category/:categoryId' component={ Category } />
    </Switch>
)

export default App;
import * as types from '../constants/UserActionTypes'
import axios from 'axios'

export function userRegisterSuccess(userId, userName) {
    return {
        type: types.USER_REGISTER_SUCCESS,
        userId,
        userName
    }
}

export function userLoginSuccess(userId, userName) {
    return {
        type: types.USER_LOG_IN_SUCCESS,
        userId,
        userName
    }
}

export function userSetData(userData) {
    let userJson = { ...userData, userId: userData.id, userName: userData.username, loggedIn: true, ajaxCompleted: true };
    return {
        type: types.USER_SET_DATA,
        userJson
    }
}

export function userGetInfo() {
    return async function (dispatch) {
        let result = {};
        await axios.get('http://www.liaofushen.xyz/user/').then((response) => {
            console.log(response.data);
            let data = response.data;
            if (data.status === 0 && data.data) {
                let userData = data.data;
                result = { ...userData, userId: userData.id, userName: userData.username, loggedIn: true, ajaxCompleted: true };
            }
            else {
                result = { loggedIn: false, ajaxCompleted: true };
            }
        }).catch((error) => {
            console.log(error);
            result = { loggedIn: false, ajaxCompleted: true };
        });
        dispatch({ type: types.USER_GET_INFO, result });
    }
}

export function userLogIn(username, password) {
    return async function (dispatch) {
        let result = {};
        await timeout(1000);
        result.username = username;
        dispatch({
            type: types.USER_LOG_IN_SUCCESS,
            result
        });
    };
}

const timeout = (ms) => new Promise(resolve => setTimeout(resolve, ms)) 
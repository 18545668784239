import React from 'react'
import '../../css/common.css'
import '../../css/footer.css'
export default class Footer extends React.Component {
    render() {
        return (
            <div className='w'>
                <div className='footer-box'>
                    <div className='footer-links'>
                        <span className='footer-link'>项目一</span>|
                        <span className='footer-link'>项目二</span>|
                        <span className='footer-link'>项目三</span>
                    </div>
                    <div className='footer-copy-right'>
                        Copyright © 2019  《基于推荐算法的电商系统》毕业设计 版权所有
                    </div>
                </div>
            </div>
        );
    }
}
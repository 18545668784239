import React from 'react'
import axios from 'axios'
import { Link } from 'react-router-dom'
export default class MyCart extends React.Component {
    constructor(props) {
        super(props);
        this.state = { cartText: '购物车', cartEmpty: true, cartCount: 0, cartData: [], cartChanging: false };
    }

    deleteCartRequest(id) {
        let { cartChanging } = this.state;
        if (cartChanging) {
            return;
        }
        this.setState({ cartChanging: true });
        axios.delete(`http://www.liaofushen.xyz/cart/${id}`).then((response) => {
            let data = response.data;
            let cartData = data.data;
            if (data.status === 0) {
                let cartEmpty = cartData.length === 0 ? true: false;
                this.setState({ cartEmpty, cartData: cartData, cartCount: cartData.length, cartChanging: false });
            }
            else {
                this.setState({ cartText: data.message, cartEmpty: true, cartChanging: false });
            }
        }).catch((error) => {
            this.setState({ cartText: '加载购物车出错', cartEmpty: true, cartChanging: false });
            console.log(error);
        });
    }

    componentDidMount() {
        this.setState({ loginRequesting: true });
        axios.get('http://www.liaofushen.xyz/cart').then(function(response) {
            console.log(response.data.data);
            let data = response.data;
            let cartData = data.data;
            if (data.status === 0) {
                this.setState({ cartEmpty: false, cartData: cartData, cartCount: cartData.length });
            }
            else {
                this.setState({ cartText: data.message, cartEmpty: true, cartCount: 0 });
            }
        }.bind(this)).catch(function(error) {
            console.log(error);
            this.setState({ cartText: '购物车拉取错误', cartEmpty: true, cartCount: 0 });
        }.bind(this));
    }

    render() {
        let { cartCount, cartEmpty, cartText, cartData } = this.state;
        let dropdownDiv;
        return (
            <div className='my-cart-box'
                onMouseOver={ () => dropdownDiv.classList.remove('hide') }
                onMouseLeave={ () => dropdownDiv.classList.add('hide') }
            >
                <div className='my-cart-content'>
                    <Link style={{ color: 'red' }} to='/cart'>我的购物车</Link>
                    <div className='my-cart-count'>{ cartCount }</div>
                </div>
                <div ref={ node => dropdownDiv = node } className='my-cart-dropdown hide'>
                    {
                        cartEmpty ? <div className='my-cart-empty'>{ cartText }</div> : cartData.map((item, index) => {
                            return (
                                <div className='my-cart-item' key={ index }>
                                    <div className='my-cart-item-left'>
                                        <img alt={ item.title } className='my-cart-item-img' src={item.image} />
                                        <div className='my-cart-item-title'>{ item.title }</div>
                                    </div>
                                    <div className='my-cart-item-right'>
                                        <div className='my-cart-item-price'>{ `￥${item.price} × ${item.num}` }</div>
                                        <div className='my-cart-item-delete' onClick={ () => this.deleteCartRequest(item.id) }>删除</div>
                                    </div>
                                </div>
                            );
                        })
                    }
                    {
                        cartEmpty ? '': 
                        <div style={
                            { 
                                fontSize: '12px', 
                                color: '#888', 
                                backgroundColor: '#ddd', 
                                width: '100%', 
                                textAlign: 'left',
                                padding: '10px 0px', 
                                position: 'relative'
                            }
                        }>
                            <b style={{ color: 'black', marginLeft: '20px', marginRight: '5px' }}>
                                { cartData.reduce((total, item)=>{ return total + item.num }, 0) }
                            </b>件商品 总共
                            <span style={{ color: 'red', marginLeft: '20px' }}>
                                { `￥${cartData.reduce((total, item)=>{ return total + item.num * item.price }, 0)}` }
                            </span>
                            <Link style={{ color: 'red', marginLeft: '50px', position: 'absolute', right: '5px' }} to='/cart'>前往购物车</Link>
                        </div>
                    }
                </div>
            </div>
        );
    }
}
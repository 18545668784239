import React from 'react'
import axios from 'axios'
import Header from '../../components/Header'
import Footer from '../../components/Footer'
import { Link } from 'react-router-dom'
import '../../css/common.css'
import '../../css/user.css'
import '../../css/order.css'
export default class TakeDelivery extends React.Component {
    constructor(props) {
        super(props);
        this.state = { takeDeliverySuc: false, ajaxCompleted: false };
    }
    
    componentDidMount() {
        this.postOrderReceiveRequest();
    }

    postOrderReceiveRequest() {
        let orderId = this.props.match.params.orderId;
        axios.post(`http://www.liaofushen.xyz/order/receive/${orderId}`).then((response) => {
            let data = response.data;
            console.log(data);
            if (data.status === 0) {
                this.setState({ takeDeliverySuc: true, ajaxCompleted: true });
            }
            else {
                this.setState({ takeDeliverySuc: false, ajaxCompleted: true });
            }
        }).catch((error) => {
            console.log(error);
        });
    }

    render() {
        let { ajaxCompleted, takeDeliverySuc } = this.state;
        return (
            <div id='content'>
                <Header />
                <div className='order-head'>
                    <div className='w'>
                        <div className='order-head-content'>
                            <div className='order-head-content-logo'>
                                <Link to='/'>
                                    <img alt='分期商城' style={{ height: '100%', width: '100%' }} src='http://liaofushen.xyz:8081/logo.png' />
                                </Link>
                            </div>
                            <div className='order-head-content-words'>确认收货</div>
                        </div>
                    </div>
                </div>
                <div className='take-d-box' style={{ width: '100%', textAlign: 'center' }}>
                    { 
                        ajaxCompleted? takeDeliverySuc? 
                        <div className='take-d-content' style={{ margin: '100px' }}>
                            <span style={{ fontSize: '40px', fontWeight: '500', color: '#666' }}>收货成功</span>
                            <span style={{ marginLeft: '50px' }}><Link style={{ fontSize: '12px' }} to='/user/myOrder'>返回订单</Link></span>
                        </div>: 
                        '尊敬的客户，非常抱歉，因未知原因导致收货失败，请重新尝试。': '收货请求发送中……' 
                    }
                </div>
                <Footer />
            </div>
        );
    }
}
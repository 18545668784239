import React from 'react'
import '../../css/common.css'
import '../../css/home.css'
export default class Slider extends React.Component {
    constructor(props) {
        super(props);
        this.state = { showingIndex: 0, autoChange: true, sliderListLength: this.props.sliderList.length };
    }

    componentDidMount() {
        this.timer = setTimeout(() => this.autoChangeTimer(), 2000);
    }

    componentWillUnmount() {
        this.timer && clearTimeout(this.timer);
    }

    changeShowingIndex(index) {
        this.setState({ showingIndex: index });
    }

    autoChangeTimer() {
        let { showingIndex, autoChange, sliderListLength } = this.state;
        if (autoChange) {
            let nextIndex;
            if (showingIndex === sliderListLength - 1) {
                nextIndex = 0;
            }
            else {
                nextIndex = ++showingIndex;
            }
            this.setState(this.changeShowingIndex(nextIndex));
        }
        this.timer = setTimeout(() => this.autoChangeTimer(), 2000);
    }

    sliderMouseOver() {
        this.setState({ autoChange: false });
    }

    sliderMouseLeave() {
        this.setState({ autoChange: true });
    }

    render() {
        let { sliderList } = this.props;
        let { showingIndex } = this.state;
        return (
            <div 
                className='slider' 
                style={{ width: '1000px', overflow: 'hidden' }}
                onMouseOver={ () => this.sliderMouseOver() }
                onMouseLeave={ () => this.sliderMouseLeave() } 
            >
                <div 
                    className='slider-list' 
                    style={{ width: '3000px', 
                    overflow: 'hidden', 
                    transform: 'translate3d(0, 0, 0)' }}
                >
                    {
                        sliderList.map((item, index) => {
                            let isShowing = index === showingIndex;
                            return <div key={ index } className='slider-item' style={{
                                zIndex: isShowing ? '1000': '999',
                                opacity: isShowing ? '1': '0',
                                transition: 'opacity 500ms ease-in-out',
                                float: 'left',
                                position: 'relative',
                                width: '1000px',
                                left: '-' + index * 1000 + 'px'
                            }}><img alt='轮播图' src={ item }/></div>;
                        })
                    }
                </div>
                <ul className='slider-dots'>
                    {
                        sliderList.map((item, index) => {
                            return (
                                <li 
                                    className={ `slider-dot ${showingIndex === index ? 'slider-dot-check': ''}` } 
                                    key={ index } 
                                    onMouseOver={ () => this.changeShowingIndex(index) }
                                ></li>
                            );
                        })
                    }
                </ul>
            </div>
        );
    }
}
import React from 'react'
import { connect } from 'react-redux'
import { staffSendMsg } from '../../actions/webSocketActions'
import { sendStaffCloseChat } from '../../websocket-client'
import { getPicture } from '../../Utils/downloadPicture';
import { addUploadPictureItem } from '../../Utils/uploadPicture'
import '../../css/cs.css'

class StaffMsgBox extends React.Component {

    componentDidUpdate() {
        let ele = document.getElementsByClassName('chatting-Body-TextArea')[0];
        ele.scrollTop = ele.scrollHeight;
    }

    render() {
        let { chatState, csState, uploadPicture } = this.props;
        let { chattingWith } = csState;
        console.log(chatState, chattingWith, csState)
        let { staffId } = this.props.csStaffInfo;
        let textAreaEle;
        let sendMsg = this.props.sendMsg;
        let closeChat = this.props.closeChat;
        let focus = this.props.focus;
        return (
            <div className='staffMsgBox'>
                <div className='chatting-Head'>
                    <div className='chatting-Head-Setting'>
                        <div className='chatting-Head-Setting-Inner'>
                            <button
                                onClick={ () => closeChat(chattingWith) }
                            >关闭对话</button>
                        </div>
                    </div>
                    <div className='chatting-Head-Title'>{ /*  */ }</div>
                </div>
                <div className='chatting-Body' style={{ 'height': '450px' }}>
                    <div className='chatting-Body-TextArea'>
                        { 
                            chatState[chattingWith].chatLog.map(function (chatLog) {
                                let picPatt = /^[#][(]pic:[0-9]+[.](jpeg|jpg|png|gif)[)]/;
                                let direction;
                                if (chattingWith === chatLog.senderId) {
                                    direction = 'Left';
                                }
                                else { 
                                    direction = 'Right';
                                }
                                let message = chatLog.msg;
                                if (picPatt.test(message)) {
                                    let fileName = picPatt.exec(message)[0].split(':')[1].replace(')', '');
                                    getPicture(fileName, chattingWith);
                                    message = '图片加载中……';
                                }
                                return (
                                    <div className='textArea-Item clearfix' key={ chatLog.msgId }>
                                        <div className={ 'textArea-' + direction + 'Item-ProfilePhoto' }>
                                            <img alt='头像' className='img' src='http://liaofushen.xyz:8081/images/head-img.jpg' />
                                        </div>
                                        <div className={ 'textArea-' + direction +'Item-Triangle' }></div>
                                        <div className={ 'textArea-' + direction + 'Item-MsgBox clearfix' }>
                                            <div className={ 'textArea-' + direction + 'Item-MsgDialog' }>
                                                { message }
                                            </div>
                                        </div>
                                    </div>
                                );
                            })
                        }
                    </div>
                </div>
                <div className='chatting-Footer'>
                    <div className='chatting-Footer-Upload'>
                        <ul style={{'listStyle': 'none', 'display': 'inline'}} >
                            <li style={{ 'float': 'left' }}>
                                <span 
                                    className='chatting-Footer-UploadItem'
                                    onClick={ e => e.target.nextSibling.click() }
                                >发送图片</span><input 
                                    style={{ 'display': 'none' }}
                                    type='file' 
                                    accept='image/x-png,image/gif,image/jpeg,image/bmp'
                                    onChange={ uploadPicture.bind(this, chattingWith, staffId) }
                                />
                            </li>
                        </ul>
                    </div>
                    <div className='chatting-Footer-SendBox'>
                        <textarea 
                            onFocus={ () => focus(textAreaEle) }
                            ref={ node => textAreaEle = node } 
                            className='sendBox-TextArea'
                        ></textarea>
                    </div>
                    <button
                        id='chat-send-btn'
                        className='cs-send-btn'
                        style={{ 
                            'height': '30px', 'lineHeight': '30px', 'width': '60px',
                            'margin': '0 auto', 'padding': '0'
                        }}
                        onClick={
                            () => {
                                let textValue = textAreaEle.value;
                                if (textValue === '') {
                                    return;
                                }
                                sendMsg(
                                    chattingWith,
                                    textValue,
                                    chatState[chattingWith].msgCount,
                                    staffId
                                );
                                textAreaEle.value = '';
                                textAreaEle.focus();
                            }
                        }
                    >
                            发送
                    </button>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    chatState: state.chatState,
    csState: state.csState,
    csStaffInfo: state.csStaffInfo
});

const mapDispatchToProps = dispatch => ({
    sendMsg: (targetId, msg, msgId, staffId) => dispatch(staffSendMsg(targetId, msg, msgId, staffId)),
    closeChat: (targetId) => sendStaffCloseChat(targetId),
    focus: function(e) {
        document.onkeydown = (event) => {
            if (event.keyCode === 13) {
                event.preventDefault();
                document.getElementById('chat-send-btn').click();
            }
        };
        e.onblur = () => document.onkeydown = null;
    },
    uploadPicture: (targetId, senderId, e) => {
        if (e.target.value === '') {
            return;
        }
        addUploadPictureItem(e.target.files[0], targetId, senderId);
        e.target.value = '';
    }
});

export default connect(mapStateToProps, mapDispatchToProps)(StaffMsgBox)
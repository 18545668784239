import { createStore, applyMiddleware } from 'redux'
import thunk from 'redux-thunk'
import rootReducer from '../reducers'

export const store = createStore(
    rootReducer,
    applyMiddleware(thunk)
);

export function getStoreState() {
    return store.getState();
}

export function getStoreDispatch() {
    return store.dispatch;
}
import React from 'react'
import axios from 'axios'
import '../../css/common.css'
import '../../css/commodity.css'
import CommodityIntro from './CommodityIntro'
import SearchBox from '../../components/SearchBox'
import Footer from '../../components/Footer'
import Header from '../../components/Header'
import TabBox from './TabBox'
export default class Commodity extends React.Component {
    constructor(props) {
        super(props);
        this.state = { commodityIntro: {} };
    }

    componentDidMount() {
        let commodityId = this.props.match.params.commodityId;
        const apiUrl = `http://www.liaofushen.xyz/commodity/${commodityId}`;
        axios.get(apiUrl).then((response) => {
            let data = response.data;
            this.setState({ commodityIntro: data.data });
            console.log(data.data);
        }).catch((error) => {
            console.log(error);
        });
    }

    render() {
        let { commodityIntro } = this.state;
        console.log(commodityIntro);
        if (!('commodity' in commodityIntro)) {
            return null;
        }
        return (
            <div id='content'>
                <Header />
                <div className='w'>
                    <SearchBox defaultValue='' />
                    <CommodityIntro commodityIntro={ commodityIntro } />
                    <TabBox commodityIntro={ commodityIntro } />
                </div>
                <Footer />
            </div>
        );
    }
}
import React from 'react'
import { Link } from 'react-router-dom'
import Axios from 'axios'
import Header from '../../components/Header'
import Footer from '../../components/Footer'
import CommoditiesBox from '../search/CommoditiesBox'
import SearchBox from '../../components/SearchBox'
import '../../css/common.css'
import '../../css/search.css'
import '../../css/order.css'
export default class Category extends React.Component {
    constructor(props) {
        super(props);
        this.state = { commodities: [], ajaxCompleted: false };
    }

    getCategoryCommodity() {
        let { categoryId } = this.props.match.params;
        Axios.get(`http://www.liaofushen.xyz:8080/category/${categoryId}/commodity`).then((response) => {
            let data = response.data;
            if (data.status === 0) {
                this.setState({ commodities: data.data, ajaxCompleted: true });
            }
            else {
                alert(data.msg);
            }
        })
    }
    
    componentDidMount() {
        this.getCategoryCommodity();
    }

    render() {
        let { commodities, ajaxCompleted } = this.state;
        return (
            <div id='content'>
                <Header />
                <div className='order-head'>
                    <div className='w'>
                        <div className='order-head-content'>
                            <div className='order-head-content-logo'>
                                <Link to='/'>
                                    <img alt='分期商城' style={{ height: '100%', width: '100%' }} src='http://liaofushen.xyz:8081/logo.png' />
                                </Link>
                            </div>
                            <div className='order-head-content-words'>商品总览</div>
                        </div>
                    </div>
                </div>
                <div className='w'>
                    <SearchBox defaultValue='' />
                    { ajaxCompleted? <CommoditiesBox commodities={ commodities } />: '数据拉取中。' }
                </div>
                <Footer />
            </div>
        )
    }
}
import * as types from '../constants/UserActionTypes'

const userInitialState = {
    userName: '',
    userId: '',
    loggedIn: false
};

export function userState(state = userInitialState, action) {
    switch (action.type) {
        case types.USER_SET_DATA: {
            return { ...action.userJson };
        }
        case types.USER_LOG_IN_SUCCESS: {
            let { userName, userId } = action;
            return { ...state, loggedIn: true, userName, userId };
        }
        case types.USER_REGISTER_SUCCESS: {
            let { userName, userId } = action;
            return { ...state, loggedIn: true, userName, userId };
        }
        case types.USER_GET_INFO: {
            let { result } = action;
            return { ...result };
        }
        default:
            return state;
    }
};
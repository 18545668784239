import React from 'react'
import { connect } from 'react-redux'
import { userSendMsg, userQuitChat } from '../../actions/webSocketActions'
import { addUploadPictureItem } from '../../Utils/uploadPicture'
import { getPicture } from '../../Utils/downloadPicture'
import '../../css/cs.css'

const MsgStatus = { SENDING: 0, UNREAD: 1, READ: 2 };

class MsgBox extends React.Component {

    componentDidUpdate() {
        let ele = document.getElementsByClassName('chatting-Body-TextArea')[0];
        ele.scrollTop = ele.scrollHeight;
    }

    render() {
        let drag = this.props.drag;
        let { chattingWith, csRequestSucAndEnd } = this.props.csState;
        let { staffId } = this.props.csStaffInfo;
        let chatState = this.props.chatState;
        let sendMsg = this.props.sendMsg;
        let textAreaEle;
        let { userId, headImg } = this.props.userState;
        let focus = this.props.focus;
        let quitChat = this.props.quitChat;
        let uploadPicture = this.props.uploadPicture;
        return (
            <div className='msgBox'>
                <div className='chatting-Head' onMouseDown={ drag }>
                    <div className='chatting-Head-Setting'>
                        <div className='chatting-Head-Setting-Inner'>
                            { 
                                csRequestSucAndEnd ?
                                <button onClick={ quitChat } >退出</button> : ''
                            }
                        </div>
                    </div>
                    <div className='chatting-Head-Title'>{ `客服编号：${staffId}` }</div>
                </div>
                <div className='chatting-Body'>
                    <div className='chatting-Body-TextArea'>
                        { 
                            chatState[chattingWith].chatLog.map(function (chatLog) {
                                let picPatt = /^[#][(]pic:[0-9]+[.](jpeg|jpg|png|gif)[)]/;
                                let direction;
                                if (chattingWith === chatLog.senderId) {
                                    direction = 'Left';
                                }
                                else {
                                    direction = 'Right';
                                }
                                let textFlag;
                                switch (chatLog.msgStatus) {
                                    case MsgStatus.SENDING: textFlag = '发送中'; break;
                                    case MsgStatus.UNREAD: textFlag = '未读'; break;
                                    case MsgStatus.READ: textFlag = '已读'; break;
                                    default: textFlag = '未知错误';
                                }
                                let message = chatLog.msg;
                                if (picPatt.test(message)) {
                                    let fileName = picPatt.exec(message)[0].split(':')[1].replace(')', '');
                                    getPicture(fileName, chattingWith);
                                    message = '图片加载中……';
                                }
                                return (
                                    <div className='textArea-Item clearfix' key={ chatLog.msgId }>
                                        <div className={ 'textArea-' + direction + 'Item-ProfilePhoto' }>
                                            <img alt='头像' className='img' src={ chatLog.senderId === chattingWith? headImg: headImg } />
                                        </div>
                                        <div className={ 'textArea-' + direction +'Item-Triangle' }></div>
                                        <div className={ 'textArea-' + direction + 'Item-MsgBox clearfix' }>
                                            <div className={ 'textArea-' + direction + 'Item-MsgDialog' }>
                                                { message }
                                            </div>
                                            { 
                                                chattingWith === chatLog.senderId ? '' : 
                                                <div className='textArea-Flag'>
                                                    <div className='textArea-TextFlag'>
                                                        { textFlag }
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                );
                            })
                        }
                        {
                            csRequestSucAndEnd ?
                            <div className='textArea-CenterItem'>
                                <div className='textArea-Item clearfix'>
                                    <div className='textArea-CenterItem-Notice'>对话已结束</div>
                                </div>
                            </div> : ''
                        }
                    </div>
                </div>
                <div className='chatting-Footer'>
                    <div className='chatting-Footer-Upload'>
                        <ul style={{'listStyle': 'none', 'display': 'inline'}} >
                            <li style={{ 'float': 'left' }}>
                                <span 
                                    className='chatting-Footer-UploadItem'
                                    onClick={ e =>  e.target.nextSibling.click()  }
                                >发送图片</span><input 
                                    style={{ 'display': 'none' }}
                                    type='file' 
                                    accept='image/x-png,image/gif,image/jpeg,image/bmp'
                                    onChange={ uploadPicture.bind(this, chattingWith, userId) }
                                />
                            </li>
                        </ul>
                    </div>
                    <div className='chatting-Footer-SendBox'>
                        <textarea 
                            onFocus={ () => focus(textAreaEle) }
                            ref={ node => textAreaEle = node } 
                            className='sendBox-TextArea'>
                        </textarea>
                    </div>
                    <button
                        id='chat-send-btn'
                        className='cs-send-btn'
                        style={{ 
                            'height': '30px', 'lineHeight': '30px', 'width': '60px',
                            'margin': '0 auto', 'padding': '0'
                        }}
                        onClick={ 
                            () => {
                                if (csRequestSucAndEnd) {
                                    return;
                                }
                                let textValue = textAreaEle.value;
                                if (textValue === '') {
                                    return;
                                }
                                sendMsg(
                                    chattingWith, 
                                    textValue, 
                                    chatState[chattingWith].msgCount,
                                    userId
                                );
                                textAreaEle.value = '';
                                textAreaEle.focus();
                            }
                        }
                    >
                            发送
                    </button>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    chatState: state.chatState,
    csState: state.csState,
    userState: state.userState,
    csStaffInfo: state.csStaffInfo
});

const mapDispatchToProps = dispatch => ({
    sendMsg: (targetId, msg, msgId, userId) => dispatch(userSendMsg(targetId, msg, msgId, userId)),
    drag: function(e) {
        let ele = document.getElementsByClassName('chatBox')[0];
        let originX = e.clientX, originY = e.clientY;
        let right = window.innerWidth - ele.offsetLeft - ele.offsetWidth;
        let top = ele.offsetTop;
        document.onselectstart = () => { return false; }
        document.onmousemove = function (ev) {
            let nowX = ev.clientX, nowY = ev.clientY;
            let diffX = originX - nowX, diffY = originY - nowY;
            let nowRight = right + diffX, nowTop = top - diffY;
            ele.style.right = `${nowRight}px`;
            ele.style.top = `${nowTop}px`;
        };
        document.onmouseup = function () {
            document.onmousemove = null;
            document.onmouseup = null;
            document.onselectstart = () => { return true; }
        };
    },
    focus: function(e) {
        document.onkeydown = (event) => {
            if (event.keyCode === 13) {
                event.preventDefault();
                document.getElementById('chat-send-btn').click();
            }
        };
        e.onblur = () => document.onkeydown = null;
    },
    quitChat: () => dispatch(userQuitChat()),
    uploadPicture: (targetId, senderId, e) => {
        if (e.target.value === '') {
            return;
        }
        addUploadPictureItem(e.target.files[0], targetId, senderId);
        e.target.value = '';
    }
});

export default connect(mapStateToProps, mapDispatchToProps)(MsgBox)
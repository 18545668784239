import { downloadPictureFragment, downloadPictureStart } from '../websocket-client';
import { getStoreDispatch } from '../store'
import * as types from '../constants/ActionTypes'

let downloadList = {};
let readyList = {};

export function getPicture(fileName, targetId) {
    if (downloadList[fileName]) {
        console.log('bbb');
        if (downloadList[fileName].finished) {
            return downloadList[fileName].dataUrl;
        }
        else {
            return false;
        }
    }
    else {
        console.log('aaa');
        let replaceText = `#(pic:${fileName})`;
        downloadList[fileName] = { dataUrl: '', finished: false, targetId, replaceText };
        readyList[fileName] = { dataUrl: '', targetId, replaceText };
        // ws send
        downloadPictureStart(fileName);
        return false;
    }
}

export function downloadPictureStartSuccess(fileName, dataUrlHead, downloadId) {
    downloadList[downloadId] = { ...readyList[fileName] };
    downloadList[downloadId].dataUrl += dataUrlHead;
    delete readyList[fileName];
    downloadPictureFragment(downloadId);
}

export function downloadNextPictureFragment(fragment, downloadId) {
    downloadList[downloadId].dataUrl += fragment;
    downloadPictureFragment(downloadId);
}

export function downloadPictureEnd(downloadId) {
    let { targetId, dataUrl, replaceText } = downloadList[downloadId];
    getStoreDispatch()({ 
        type: types.DOWNLOAD_PICTURE_COMPLETE, 
        targetId,
        dataUrl,
        replaceText
    });
    delete downloadList[downloadId];
}
//webSocketActions
// WebSocket的连接状态改变的Action
export const WEB_SOCKET_READY_STATE_CHANGE = 'WEB_SOCKET_READT_STATE_CHANGE'
// 请求客服成功的Action
export const REQUEST_CS_SUCCESS = 'REQUEST_CS_SUCCESS'
// 有新消息时触发的Action
export const PUSH_CHAT_LOG = 'PUSH_CHAT_LOG'
// 接收消息时触发的Action
export const RECEIVE_MSG = 'RECEIVE_MSG'
// 发送小时时触发的Action
export const USER_SEND_MSG = 'USER_SEND_MSG'
// 发送信息成功的Action
export const USER_SEND_MSG_SUCCESS = 'USER_SEND_MSG_SUCCESS'
// 发送的消息已被读的Action
export const MSG_GET_READ = 'MSG_GET_READ'
// 客服接受用户请求成功的Action
export const GET_CUSTOMER_SUCCESS = 'GET_CUSTOMER_SUCCESS'
// 用户登陆WS的Action
export const USER_WS_LOG_IN = 'USER_WS_LOG_IN'
// 用户请求客服的Action
export const USER_REQUEST_CS = 'USER_REQUEST_CS'
// 用户取消请求客服的Action
export const USER_CANCEL_REQUEST_CS = 'USER_CANCEL_REQUEST_CS'
// 会话结束的Action
export const CS_CHAT_END = 'CS_CHAT_END'
// 退出会话的Action
export const USER_QUIT_CHAT = 'USER_QUIT_CHAT'
// 来自自己的信息
export const MSG_FROM_OWN = 'MSG_FROM_OWN'
// 已经有一个会话正在进行
export const CHAT_ALREADY_EXISTS = 'CHAT_ALREADY_EXISTS'


//Staff
// 记录Staff信息
export const STAFF_SET_INFO = 'STAFF_SET_INFO'
// Staff登陆WS的Action
export const STAFF_WS_LOG_IN = 'STAFF_WS_LOG_IN'
// Staff接收请求的用户的Action
export const STAFF_ACCEPT_CUSTOMER = 'STAFF_ACCEPT_CUSTOMER'
// Staff打开固定用户的对话框的Action
export const STAFF_CHATTING_WITH = 'STAFF_CHATTING_WITH'
// Staff发送信息的Action
export const STAFF_SEND_MSG = 'STAFF_SEND_MSG'
// Staff发送信息成功的Action
export const STAFF_SEND_MSG_SUCCESS = 'STAFF_SEND_MSG_SUCCESS'
// Staff结束会话的Action
export const STAFF_CLOSE_CHAT = 'STAFF_CLOSE_CHAT'
// Staff结束会话成功的Action
export const STAFF_CLOSE_CHAT_SUCCESS = 'STAFF_CLOSE_CHAT_SUCCESS'

// 读取消息时的Action
export const READ_MSG = 'READ_MSG'
// 发送图片信息时的Action
export const SEND_PICTURE_INFO = 'SEND_PICTURE_INFO'
// 发送图片信息成功的Action
export const SEND_PICTURE_INFO_SUCCESS = 'SEND_PICTURE_INFO_SUCCESS'
// 发送图片碎片的Action
export const SEND_PICTURE_FRAGMENT = 'SEND_PICTURE_FRAGMENT'
// 发送图片碎片成功的Action
export const SEND_PICTURE_FRAGMENT_SUCCESS = 'SEND_PICTURE_FRAGMENT_SUCCESS'
// 发送图片结束的Action
export const SEND_PICTURE_END = 'SEND_PICTURE_END'
// 发送图片结束成功的Action
export const SEND_PICTURE_END_SUCCESS = 'SEND_PICTURE_END_SUCCESS'
// 开始下载图片的Action
export const DOWNLOAD_PICTURE_START = 'DOWNLOAD_PICTURE_START'
// 开始下载图片成功的Action
export const DOWNLOAD_PICTURE_START_SUCCESS = 'DOWNLOAD_PICTURE_START_SUCCESS'
// 下载图片碎片的Action
export const DOWNLOAD_PICTURE_FRAGMENT = 'DOWNLOAD_PICTURE_FRAGMENT'
// 下载图片碎片成功的Action
export const DOWNLOAD_PICTURE_FRAGMENT_SUCCESS = 'DOWNLOAD_PICTURE_FRAGMENT_SUCCESS'
// 下载图片结束的Action
export const DOWNLOAD_PICTURE_END = 'DOWNLOAD_PICTURE_END'
// 下载图片完成的Action
export const DOWNLOAD_PICTURE_COMPLETE = 'DOWNLOAD_PICTURE_COMPLETE'

// 等待客服的人数的Action
export const CS_WAITING_COUNT = 'CS_WAITING_COUNT'
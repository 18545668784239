import React from 'react'
import '../../css/common.css'
import '../../css/commodity.css'
export default class TabBox extends React.Component {
    constructor(props) {
        super(props);
        this.state = { tabIndex: 0 };
    }

    changeTabIndex(index) {
        this.setState({ tabIndex: index });
    }

    render() {
        let { tabIndex } = this.state;
        let { commodity, comment } = this.props.commodityIntro;
        let { desc } = commodity;
        let { avgStarLevel, comments } = comment;
        return (
            <div className='cmdt-tab-box'>
                <ul>
                    <li className={ tabIndex === 0 ? 'cmdt-tab-checking': 'cmdt-tab-box-item' } onClick={ () => this.changeTabIndex(0) }>商品介绍</li>
                    <li className={ tabIndex === 1 ? 'cmdt-tab-checking': 'cmdt-tab-box-item' } onClick={ () => this.changeTabIndex(1) }>评论</li>
                </ul>
                <div className={ `cmdt-tab-desc ${tabIndex !== 0 ? 'hide': ''}` }>
                    {
                        desc.map((item, index) => {
                            return <div key={ index }><img alt='商品介绍' src={ item } /></div>
                        })
                    }
                </div>
                <div className={ `cmdt-tab-comment ${tabIndex !== 1 ? 'hide': ''}` }>
                    <div className='cmdt-tab-comment-head'>
                        <span>评价</span>
                    </div>
                    { 
                        comments.length === 0? <div className='cmdt-tab-comment-no-comment'>该商品暂无评论</div>: 
                        <div className='cmdt-tab-comment-avgStarLevel'>
                            总体评分：<span style={{ color: 'red', fontSize: '40px', marginLeft: '20px' }}>{ avgStarLevel }</span>
                        </div>
                    }
                    { comments.length === 0? '': 
                    <div className='cmdt-tab-comment-content'>
                        {
                            comments.map((item, index) => {
                                console.log(item);
                                let { user } = item;
                                return (
                                    <div className='comment-item' key={ index }>
                                        <div className='comment-item-userInfo'>
                                            <div className='comment-item-headImg'>
                                                <img style={{ width: '100%', height: '100%' }} alt={ user.username } src={ user.headImg } />
                                            </div>
                                            <span style={{ color: '#666', fontSize: '12px', verticalAlign: 'middle' }}>
                                                { user.username }
                                            </span>
                                        </div>
                                        <div className='comment-item-content'>
                                            <div className='comment-item-starLevel'>
                                                用户评分：<span style={{ color: 'red', fontSize: '20px' }}>{ item.starLevel }</span>
                                            </div>
                                            <div className='comment-item-desc'>
                                                { item.desc }
                                            </div>
                                            <div className='comment-item-updated'>
                                                { item.updated }
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                    }
                </div>
            </div>
        );
    }
}
import React from 'react'
import { Link } from 'react-router-dom'
import '../../css/user.css'
export default class MenuTree extends React.Component {
    render() {
        console.log(this.props);
        let { pathname } = this.props;
        return (
            <aside className='user-menu'>
                <div className='user-menu-head'>个人中心</div>
                <ul className='user-menu-tree'>
                    <li>
                        {
                            pathname === '/user/information'? <div className='user-menu-tree-item-checking'>个人资料</div>:
                                <Link to='/user/information'>个人资料</Link>
                        }
                    </li>
                    <li>
                        {
                            pathname === '/user/myOrder'? <div className='user-menu-tree-item-checking'>我的订单</div>:
                                <Link to='/user/myOrder'>我的订单</Link>
                        }
                    </li>
                    <li>
                        {
                            pathname === '/user/address'? <div className='user-menu-tree-item-checking'>收货地址</div>:
                                <Link to='/user/address'>收货地址</Link>
                        }
                    </li>
                </ul>
            </aside>
        );
    }
}
import React from 'react'
import { connect } from 'react-redux'
import { staffSetInfo } from '../../actions/webSocketActions'
import { startWebSocket } from '../../websocket-client'
import Footer from '../../components/Footer'
import '../../css/common.css'
import '../../css/order.css'
import '../../css/register.css'

class StaffLogIn extends React.Component {
    render () {
        let logIn = this.props.logIn;
        let staffIdInput;
        return (
            <div id='content'>
                <div className='order-head'>
                    <div className='w'>
                        <div className='order-head-content'>
                            <div className='order-head-content-logo'>
                                <img alt='分期商城' style={{ height: '100%', width: '100%' }} src='http://liaofushen.xyz:8081/logo.png' />
                            </div>
                            <div className='order-head-content-words'>客服工作人员登陆</div>
                        </div>
                    </div>
                </div>
                <div className='w'>
                <div className='register-form'>
                        <div className='form-item'>
                            <div className='form-title'>登陆</div>
                        </div>
                        <div className='form-item'>
                            <span className='item-head'>工号</span>
                            <span className='item-content'>
                                <input ref={ node => staffIdInput = node } className='form-input' placeholder='在此输入工号' type='text' />
                            </span>
                        </div>
                        <div className='form-item'>
                            <button 
                                className='form-register-btn' 
                                onClick={ () => logIn(staffIdInput.value) }
                            >登陆</button>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        );
    }
}

const mapStateToProps = state => ({
    
});

const mapDispatchToProps = dispatch => ({
    logIn: (staffId) => { 
        let staffInfo = { staffId };
        dispatch(staffSetInfo(staffInfo)); 
        startWebSocket(true);
    }
});

export default connect(mapStateToProps, mapDispatchToProps)(StaffLogIn);
import React from 'react'
import { Switch, Route } from 'react-router-dom'
import MenuTree from './MenuTree'
import Information from './Information'
import Address from './Address'
import MyOrder from './MyOrder'
import { connect } from 'react-redux'
import { userGetInfo } from '../../actions/userActions'
import Header from '../../components/Header'
import Footer from '../../components/Footer'
import '../../css/user.css'
import '../../css/common.css'
class User extends React.Component {  
    componentDidMount() {
        this.props.userGetInfo();
    }

    render() {
        let { userState } = this.props;
        let { ajaxCompleted, loggedIn } = userState;
        if (ajaxCompleted && !loggedIn) {
            window.location.href = `/#/login?returnUrl=${window.location.href}`;
        }
        else if (!ajaxCompleted) {
            return null;
        }
        console.log(this.props);
        return (
            <div id='content'>
                <Header />
                <div className='w' style={{ marginTop: '30px', overflow: 'hidden' }}>
                    <div className='user-col-menu'>
                        <MenuTree pathname={ this.props.location.pathname } />
                    </div>
                    <div className='user-col-content'>
                        <Switch>
                            <Route exact path='/user' component={ Information } />
                            <Route path='/user/information' component={ Information } />
                            <Route path='/user/address' component={ Address } />
                            <Route path='/user/myOrder' component={ MyOrder } />
                        </Switch>
                    </div>
                </div>
                <Footer />
            </div>
        );
    }
}

const mapStateToProps = state => ({
    userState: state.userState
});

const mapDispatchToProps = dispatch => ({
    userGetInfo: () => dispatch(userGetInfo())
});

export default connect(mapStateToProps, mapDispatchToProps)(User);
import React from 'react'
import axios from 'axios'
import { Link } from 'react-router-dom'
import Header from '../../components/Header'
import Footer from '../../components/Footer'
import '../../css/common.css'
import '../../css/order.css'
export default class InsterestOrder extends React.Component {
    constructor(props) {
        super(props);
        this.state = { cartData: [], ajaxCompleted: false, receiveAddrData: [], receiverAddrAjaxCompleted: false, addrValue: -1, installments: [] };
    }

    componentDidMount() {
        this.getUserInfoRequest();
    }

    getUserInfoRequest() {
        axios.get('http://www.liaofushen.xyz/user/').then((response) => {
            console.log(response.data);
            let data = response.data;
            if (data.status === 0 && data.data) {
                this.getCommodityRequest();
                this.getReceiveAddrRequest();
            }
            else {
                window.location.href = `/#/login?returnUrl=${window.location.href}`;
            }
        }).catch((error) => {
            console.log(error);
        });
    }

    addrSelected(value) {
        value = parseInt(value);
        this.setState({ addrValue: value });
    }

    postInsterestOrderRequest() {
        let { buyCount, installmentIndex } = this.props.match.params;
        let { cartData, addrValue, installments } = this.state;
        if (addrValue === -1) {
            alert("请选择地址信息。");
            return;
        }
        let requestJson = {
            orderItems: cartData.map((item) => ({ commodityId: item.id, commodityNum: buyCount })),
            receiveAddr: {
                id: addrValue
            },
            installmentNum: installments[installmentIndex].times
        };
        axios.post('http://www.liaofushen.xyz/interestOrder', requestJson).then((response) => {
            console.log(response.data);
            let data = response.data;
            if (data.status === 0) {
                window.location.href = `/#/pay/${data.data.id}`;
            }
        }).catch((error) => {
            console.log(error);
        });
    }

    getReceiveAddrRequest() {
        axios.get('http://www.liaofushen.xyz/receiveAddr').then((response) => {
            console.log(response.data);
            let data = response.data;
            let receiveAddrData = data.data;
            if (data.status === 0) {
                this.setState({ receiveAddrData: receiveAddrData, receiverAddrAjaxCompleted: true });
            }
            else {
                this.setState({ receiverAddrAjaxCompleted: true });
            }
        }).catch((error) => {
            console.log(error);
        });
    }

    getCommodityRequest() {
        let commodityId = this.props.match.params.commodityId;
        axios.get(`http://www.liaofushen.xyz/commodity/${commodityId}`).then((response) => {
            console.log(response.data);
            let data = response.data;
            let cartData = data.data.commodity;
            if (data.status === 0) {
                this.setState({ cartData: [cartData], installments: data.data.installment, ajaxCompleted: true });
            }
            else {
                this.setState({ ajaxCompleted: true });
            }
        }).catch((error) => {
            console.log(error);
        });
    }

    render() {
        let { buyCount, installmentIndex } = this.props.match.params;
        let { cartData, ajaxCompleted, receiveAddrData, receiverAddrAjaxCompleted, addrValue, installments } = this.state;
        if (!ajaxCompleted) {
            return null;
        }
        let addrSelect;
        return (
            <div id='content'>
                <Header />
                <div className='order-head'>
                    <div className='w'>
                        <div className='order-head-content'>
                            <div className='order-head-content-logo'>
                                <img alt='分期商城' style={{ height: '100%', width: '100%' }} src='http://liaofushen.xyz:8081/logo.png' />
                            </div>
                            <div className='order-head-content-words'>订单</div>
                        </div>
                    </div>
                </div>
                <div className='w'>
                    <div className='o-cart-box'>
                        <div className='o-cart-head'>
                            <span style={{ marginLeft: '50px' }}>商品</span>
                            <span style={{ marginLeft: '520px' }}>单价</span>
                            <span style={{ marginLeft: '80px' }}>数量</span>
                            <span style={{ marginLeft: '90px' }}>总计</span>
                        </div>
                        <div className='o-cart-content'>
                            {
                                cartData.map((item, index) => {
                                    return (
                                        <div className='cart-item' key={ index }>
                                            <div className='cart-cell cart-item-img'>
                                                <img alt={ item.title } style={{ width: '100%', height: '100%' }} src={ item.image } />
                                            </div>
                                            <Link to={ `/commodity/${item.id}` }>
                                                <div className='cart-cell cart-item-title'>
                                                    { item.title }
                                                </div>
                                            </Link>
                                            <div className='cart-cell cart-item-params'>
                                                { `颜色：${item.colour}` } <br />
                                                { `尺寸：${item.size}` }
                                            </div>
                                            <div className='cart-cell cart-item-price'>
                                                { `￥${item.price}` }
                                            </div>
                                            <div className='cart-cell cart-item-num' style={{ textAlign: 'center' }}>
                                                { buyCount }
                                            </div>
                                            <div className='cart-cell cart-item-total'>
                                                { 
                                                    `￥${(buyCount * installments[installmentIndex].perPrice).toFixed(2)}起
                                                    × ${installments[installmentIndex].times}期` 
                                                }
                                            </div>
                                        </div>
                                    );
                                })
                            }
                        </div>
                        <div className='order-receiveAddr-box'>
                            {
                                receiverAddrAjaxCompleted ? receiveAddrData.length === 0 ? '没有已登记的地址信息': 
                                <div>
                                    <div style={{ color: 'red', margin: '30px 0px 15px 0px' }}>收货地址信息</div>
                                    <span>地址：</span><select
                                        style={{ verticalAlign: 'middle' }} 
                                        ref={ node => addrSelect = node } 
                                        onChange={ () => this.addrSelected(addrSelect.value) }
                                    >
                                        <option value='-1'>请选择地址信息</option>
                                        {
                                            receiveAddrData.map((item, index) => {
                                                let { province, city, county, addr, receiverName, phone, zipCode, id } = item;
                                                return (
                                                    <option value={ id } key={ index }>
                                                        { 
                                                            `${province ? province: ''} ${city ? city: ''} ${county ? county: ''} 
                                                            ${addr ? addr: ''}， ${receiverName}，${phone}， ${zipCode ? zipCode: ''}` 
                                                        }
                                                    </option>
                                                );
                                            })
                                        }
                                    </select>
                                    <div style={{ margin: '20px 0px 20px 20px' }}>
                                        {
                                            addrValue === -1? '': receiveAddrData.map((item) => {
                                                if (item.id === addrValue) {
                                                    let { province, city, county, addr, receiverName, phone, zipCode, id } = item;
                                                    return (
                                                        <div key={ id }>
                                                            {`${receiverName}， ${phone}`}
                                                            <br />
                                                            {`${province ? province: ''} ${city ? city: ''} ${county ? county: ''} 
                                                                ${addr ? addr: ''}`}
                                                            <br />
                                                            {`${zipCode ? zipCode: ''}`}
                                                        </div>
                                                    ); 
                                                }
                                                return '';
                                            })
                                        }
                                    </div>
                                </div>:
                                '数据拉取中'
                            }
                        </div>
                        <div className='cart-box-foot'>
                            <div className='cart-box-foot-btn' onClick={ () => this.postInsterestOrderRequest() }>支付</div>
                            <span className='cart-box-foot-total'>
                                <span className='cart-box-foot-total-head'>先付价格：</span>
                                <span className='cart-box-foot-total-content'>
                                    { `￥${(buyCount * installments[installmentIndex].perPrice).toFixed(2)}` }
                                </span>
                            </span>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        );
    }
}
import React from 'react'
import axios from 'axios'
import '../../css/common.css'
import '../../css/admin.css'
import '../../css/user.css'
export default class CommodityManage extends React.Component {
    constructor(props) {
        super(props);
        this.state = { checkingIndex: 0, categoryList: [], sendCategoryAjax: false };
    }

    getCategoryAllRequest() {
        let { sendCategoryAjax } = this.state;
        if (sendCategoryAjax) {
            return;
        }
        this.setState({ sendCategoryAjax: true });
        axios.get(`http://liaofushen.xyz:8080/category/all`).then((response) => {
            let data = response.data;
            if (data.status === 0) {
                this.setState({ categoryList: data.data });
            }
        }).catch((error) => {
            console.log(error);
        });
    }

    postManagerCommodityRequest() {
        let form = document.getElementById('formManagerCommodity');
        let formData = new FormData(form);
        axios.post(`http://liaofushen.xyz:8080/manager/commodity`, formData, {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        }).then((response) => {
            let data = response.data;
            if (data.status === 0) {
                alert('添加商品成功。');
                form.reset();
            }
            else {
                alert(data.message);
            }
        }).catch((error) => {
            console.log(error);
        })
    }

    showData(data) {
        console.log(data);
    }

    render() {
        let { checkingIndex, categoryList } = this.state;
        let categorySelect, titleInput, sellPointInput, priceInput, numInput, weightInput, sizeInput, colorInput;
        let cmContent = {
            0: (
                <div>
                    <form id='formManagerCommodity' method='post' className='user-info-box' encType='multipart/form-data'>
                        <div className='info-box-item'>
                            <div className='info-box-item-head'>类别：</div>
                            <div className='info-box-item-content'>
                                <select 
                                    name='categoryId'
                                    ref={ node => categorySelect = node } 
                                    onClick={ () => this.getCategoryAllRequest() }
                                >
                                    {
                                        categoryList.length === 0? <option>--</option>:
                                        categoryList.map((item, index) => (<option key={ index } value={ item.id }>{ item.name }</option>))
                                    }
                                </select>
                            </div>
                        </div>
                        <div className='info-box-item'>
                            <div className='info-box-item-head'>标题：</div>
                            <div className='info-box-item-content'>
                                <input name='title' ref={ node => titleInput = node } className='info-box-item-input' />
                            </div>
                        </div>
                        <div className='info-box-item'>
                            <div className='info-box-item-head'>卖点：</div>
                            <div className='info-box-item-content'>
                                <input name='sellPoint' ref={ node => sellPointInput = node } className='info-box-item-input' />
                            </div>
                        </div>
                        <div className='info-box-item'>
                            <div className='info-box-item-head'>价格：</div>
                            <div className='info-box-item-content'>
                                <input name='price' ref={ node => priceInput = node } className='info-box-item-input' />
                            </div>
                        </div>
                        <div className='info-box-item'>
                            <div className='info-box-item-head'>数量：</div>
                            <div className='info-box-item-content'>
                                <input name='num' ref={ node => numInput = node } className='info-box-item-input' />
                            </div>
                        </div>
                        <div className='info-box-item'>
                            <div className='info-box-item-head'>重量：</div>
                            <div className='info-box-item-content'>
                                <input name='weight' ref={ node => weightInput = node } className='info-box-item-input' />
                            </div>
                        </div>
                        <div className='info-box-item'>
                            <div className='info-box-item-head'>尺寸：</div>
                            <div className='info-box-item-content'>
                                <input name='size' ref={ node => sizeInput = node } className='info-box-item-input' />
                            </div>
                        </div>
                        <div className='info-box-item'>
                            <div className='info-box-item-head'>颜色：</div>
                            <div className='info-box-item-content'>
                                <input name='color' ref={ node => colorInput = node } className='info-box-item-input' />
                            </div>
                        </div>
                        <div className='info-box-item'>
                            <div className='info-box-item-head'>图片：</div>
                            <div className='info-box-item-content'>
                                <input name='commodity_img' type='file' className='info-box-item-input' />
                            </div>
                        </div>
                        <div className='info-box-item'>
                            <div className='info-box-item-head'>描述图片：</div>
                            <div className='info-box-item-content'>
                                <input name='desc_img' type='file' className='info-box-item-input' />
                            </div>
                        </div>
                        
                    </form>
                    <button onClick={ () => this.postManagerCommodityRequest({
                        categoryId: categorySelect.options[categorySelect.selectedIndex].value,
                        title: titleInput.value,
                        sellPoint: sellPointInput.value,
                        price: priceInput.value,
                        num: numInput.value,
                        weight: weightInput.value,
                        size: sizeInput.value,
                        color: colorInput.value
                    }) }>提交</button>
                </div>
            )
        };
        return (
            <div className='o-m-box'>
                <div className='o-m-left'>
                    <ul className='o-m-left-ul'>
                        <li className={ `${checkingIndex === 0? 'o-m-left-ul-li-checking': ''}` }>添加商品</li>
                    </ul>
                </div>
                <div className='o-m-right'>
                    <div className='o-m-right-head'>
                        <div className='o-m-right-head-icon'></div>
                        添加商品
                    </div>
                    {
                        cmContent[checkingIndex]
                    }
                </div>
            </div>
        );
    }
}
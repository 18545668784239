import React from 'react'
import axios from 'axios'
import { Link } from 'react-router-dom'
import '../../css/common.css'
import '../../css/search.css'
import '../../css/recommend.css'
import '../../css/home.css'
export default class RecommendBox extends React.Component {
    constructor(props) {
        super(props);
        this.state = { showingIndex: 0, commoditiesList: [] };
    }

    componentDidMount() {
        this.getCommodityRecommendRequest();
    }

    changeShowingIndex(index) {
        this.setState({ showingIndex: index });
    }

    getCommodityRecommendRequest() {
        axios.get('http://liaofushen.xyz:8080/commodity/recommend').then((response) => {
            let data = response.data;
            console.log(data);
            if (data.status === 0) {
                let commodities = data.data;
                let commoditiesList = [];
                const groupCount = 5;
                let cListLength = commodities.length;
                for(let i = 0; i < cListLength; i += groupCount) {
                    commoditiesList.push(commodities.splice(0, groupCount));
                }
                this.setState({ commoditiesList: commoditiesList });
            }
        }).catch((error) => {
            console.log(error);
        });
    }

    render() {
        let { showingIndex, commoditiesList } = this.state;
        return (
            <div id='recommend' style={{ width: '1000px', overflow: 'hidden', marginTop: '30px' }}>
                <h3 style={{ marginBottom: '10px' }}>为你推荐</h3>
                <hr style={{ borderWidth: '2px' }} />
                <div 
                    className='rb-content' 
                    style={{ 
                        width: `${commoditiesList.length * 1000}px`, 
                        height: '315px',
                        overflow: 'hidden', 
                        transform: 'translate3d(0, 0, 0)' 
                    }}
                >
                    {
                        commoditiesList.map((item, index) => {
                            let isShowing = showingIndex === index;
                            return (
                                <div 
                                    className='rb-c-box' 
                                    key={ index }
                                    style={{
                                        zIndex: isShowing ? '1000': '999',
                                        opacity: isShowing ? '1': '0',
                                        transition: 'opacity 500ms ease-in-out',
                                        float: 'left',
                                        position: 'relative',
                                        width: '1000px',
                                        left: '-' + index * 1000 + 'px'
                                    }}
                                >
                                    {
                                        item.map((item1, index1) => {
                                            return (
                                                <Link to={`/commodity/${item1.id}`} key={ index1 }>
                                                    <div className='commodity-box'>
                                                        <img alt={ item1.title } className='commodity-img' src={item1.image} />
                                                        <div className='commodity-price'>{ `￥${item1.price}` }</div>
                                                        <div className='commodity-title' title={ item1.title }>{ item1.title }</div>
                                                    </div>
                                                </Link>
                                            );
                                        })
                                    }
                                </div>
                            )
                        })
                    }
                    <ul className='slider-dots'>
                        {
                            commoditiesList.length > 1? commoditiesList.map((item, index) => {
                                return (
                                    <li 
                                        className={ `slider-dot ${showingIndex === index ? 'slider-dot-check': ''}` } 
                                        key={ index } 
                                        onMouseOver={ () => this.changeShowingIndex(index) }
                                    ></li>
                                );
                            }): ''
                        }
                    </ul>
                </div>
            </div>
        )
    }
}
import React from 'react'
import OrderManage from './OrderManage'
import CommodityManage from './CommodityManage'
import AdminLogin from './AdminLogin'
import '../../css/common.css'
import '../../css/admin.css'
export default class Admin extends React.Component {
    constructor(props) {
        super(props);
        this.state = { checkingIndex: 0, loggedIn: false };
    }

    switchMenu(index) {
        this.setState({ checkingIndex: index });
    }

    adminLoginSuccess() {
        this.setState({ loggedIn: true });
    }

    render() {
        let { checkingIndex, loggedIn } = this.state;
        let adminContent = {
            0: (<OrderManage />),
            1: (<CommodityManage />)
        };
        return (
            loggedIn ?
            <div id='content'>
                <div className='w'>
                    <div className='admin-nav'>
                        <ul className='admin-nav-ul'>
                            <li onClick={ () => this.switchMenu(0) } className={ `${checkingIndex === 0? 'admin-nav-checking': ''}` }>订单管理</li>
                            <li onClick={ () => this.switchMenu(1) } className={ `${checkingIndex === 1? 'admin-nav-checking': ''}` }>商品管理</li>
                        </ul>
                    </div>
                    <div className='admin-content'>
                        {
                            adminContent[checkingIndex]
                        }
                    </div>
                </div>
            </div>: <AdminLogin adminLoginSuccess={ this.adminLoginSuccess.bind(this) } />
        );
    }
}
import React from 'react'
import { connect } from 'react-redux'
import MsgBox from './MsgBox'
import { startWebSocket, sendUserCancelRequestCS, sendUserRequestCS } from '../../websocket-client'
import '../../css/cs.css'

/*const WebSocketReadyState = {
    CONNECTING: 0,
    OPEN: 1,
    CLOSED: 2,
    CLOSING: 3
}*/

class DeskTop extends React.Component {
    constructor(props) {
        super(props);
        if (this.props.userState.loggedIn)
            startWebSocket(false);
    }

    componentWillReceiveProps(nextProps) {
        console.log(nextProps);
        console.log(this.props);
        if (this.props.userState.loggedIn === false && nextProps.userState.loggedIn === true) {
            startWebSocket(false);
        }
    }

    render() {
        let visible = this.props.deskTopVisible ? 'visible': 'hidden';
        let { loggedIn } = this.props.userState
        let { wsReadyState, wsLoggedIn, csRequesting, csRequestSuc, csRequestSucAndEnd, waitingCount } = this.props.csState;
        let requestCS = this.props.requestCS;
        let cancelRequestCS = this.props.cancelRequestCS;
        let input;
        let csLoggedInBox = (
            <div className='csRequestBox clearfix' style={{ visibility: visible }}>
                您需要登陆后才能使用客服功能。
            </div>
        );
        let csWrongBox = (
            <div className='csRequestBox clearfix' style={{ visibility: visible }}>
                readyState: { wsReadyState } <br />
                csRequesting: { csRequesting } <br />
                连接出错。
            </div>
        );
        let csRequestBox = (
            <div className='csRequestBox clearfix' style={{ visibility: visible }}>
                <textarea className='csRequestText' ref={ node => input = node } placeholder='请在此输入您的问题。' />
                <button 
                    onClick={ () => requestCS(input.value) }
                    className='csRequestBtn'
                >
                    呼叫
                </button>
            </div>
        );
        let csRequestingBox = (
            <div className='csRequestBox clearfix' style={{ visibility: visible }}>
                <div className='csRequestWaiting' >
                    { `在您前面，共有 ${waitingCount} 名用户在等待。` }
                </div>
                <button 
                    onClick={ cancelRequestCS }
                    className='csRequestBtn'
                >
                    取消
                </button>
            </div>
        );
        let csRequestSucBox = (
            <div className='chatBox clearfix' style={{ visibility: visible }}>
                <MsgBox />
                {/*<StaffInfo />*/}
            </div>
        );
        return ( csRequestSucAndEnd || csRequestSuc
            ? csRequestSucBox : csRequesting 
            ? csRequestingBox : wsLoggedIn 
            ? csRequestBox : loggedIn 
            ? csWrongBox : csLoggedInBox
        );
    }
}

const mapStateToProps = state => ({
    userState: state.userState,
    csState: state.csState
});

const mapDispatchToProps = dispatch => ({
    requestCS: (questionMsg) => {
        if (questionMsg === '') {
            return;
        }
        sendUserRequestCS(questionMsg);
    },
    cancelRequestCS: () => sendUserCancelRequestCS()
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(DeskTop);
import React from 'react'
import Navigation from './Navigation'
import Slider from './Slider'
import CategoryBox from './CategoryBox'
import SearchBox from '../../components/SearchBox/index'
import RecommendBox from '../../components/RecommendBox'
import Header from '../../components/Header'
import Footer from '../../components/Footer'
import '../../css/common.css'
import '../../css/home.css'

export default class Home extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
        this.sendApiHome();
    }

    sendApiHome() {
        const apiUrl = 'http://liaofushen.xyz:8080/home';
        const options = {
            method: 'get'
        };
        fetch(apiUrl, options).then((response) => {
            if (response.ok) {
                return response.json();
            }
            else {
                return Promise.reject('wrong.');
            }
        }).then((data) => {
            console.log(data.data);
            this.setState({ homeData: data.data });
        }).catch((error) => {
            console.log(error);
        });
    }

    render () {
        if (!('homeData' in this.state))
            return null;
        console.log(this.state.homeData);
        let categoryList = this.state.homeData['category-list'];
        let sliderList = this.state.homeData['slideshow-list'];
        let keywordList = this.state.homeData['keyword-list'];
        console.log(categoryList);
        return (
            
            <div id='content' style={{ backgroundColor: '#f0f3ef' }}>
                <Header />
                <div className='w'>
                    <SearchBox defaultValue='' />
                    <Navigation categoryList={ categoryList } keywordList={ keywordList } />
                    <Slider sliderList={ sliderList } />
                    <RecommendBox />
                    <CategoryBox keywordList={ keywordList } />
                </div>
                <Footer />
            </div>
        );
    }
}
import React from 'react'
import { Link } from 'react-router-dom'
import '../../css/common.css'
import '../../css/home.css'
export default class Navigation extends React.Component {
    render() {
        let { categoryList } = this.props;
        console.log(categoryList, this.props);
        return (
            <ul className='nav'>
                {
                    categoryList.map((item) => {
                        let navDropDownDiv;
                        return (
                            <li 
                                className='nav-category-item'
                                key={ item.id }
                                onMouseOver={ () => navDropDownDiv.classList.remove('hide') }
                                onMouseLeave={ () => navDropDownDiv.classList.add('hide') }
                            >
                                <Link to={ `/category/${item.id}` }>{ item.name }</Link>
                                <div ref={ node => navDropDownDiv = node } className='nav-dropdown hide'>
                                    {
                                        item.keywords.map((item1, index1) => {
                                            return (
                                                <Link to={ `/search?keyword=${item1}` } key={ index1 }><div className='nav-dropdown-item'>
                                                    { item1 }
                                                </div></Link>
                                            )
                                        })
                                    }
                                </div>
                            </li>
                        );
                    })
                }
                <li><div className='spacer'></div></li>
                <li style={{ cursor: 'pointer' }} onClick={ () => { document.getElementById('recommend').scrollIntoView(true); } }>为你推荐</li>
            </ul>
        );
    }
}
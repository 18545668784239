import React from 'react'
import axios from 'axios'
import Header from '../../components/Header'
import Footer from '../../components/Footer'
import { Link } from 'react-router-dom'
import '../../css/common.css'
import '../../css/pay.css'
export default class Pay extends React.Component {
    constructor(props) {
        super(props);
        this.state = { ajaxCompleted: false, payCode: '', price: '' };
    }

    componentDidMount() {
        this.getPayRequest();
    }

    postPayRequest(result) {
        if (result !== 1 && result !== 0) {
            alert('支付出错。');
            return;
        }
        let orderId = this.props.match.params['orderId'];
        axios.post(`http://www.liaofushen.xyz/pay/${orderId}?result=${result}`).then((response) => {
            console.log(response.data);
            let data = response.data;
            if (data.status === 0) {
                if (data.data) {
                    alert('支付成功，即将跳转至首页。');
                    window.location.href = '/#/';
                }
                else {
                    window.location.href = '/#/user/myOrder';
                }
            }
        }).catch((error) => {
            console.log(error);
        })
    }

    getPayRequest() {
        let orderId = this.props.match.params['orderId'];
        axios.get(`http://www.liaofushen.xyz/pay/${orderId}`).then((response) => {
            console.log(response.data);
            let data = response.data;
            if (data.status === 0) {
                this.setState({ payCode: data.data['pay-code'], ajaxCompleted: true, price: data.data['price'] });
            }
        }).catch((error) => {
            console.log(error);
        })
    }

    render() {
        let { ajaxCompleted, payCode, price } = this.state;
        return (
            <div id='content'>
                <Header />
                <div className='cart-head'>
                    <div className='w'>
                        <div className='cart-head-content'>
                            <div className='cart-head-content-logo'>
                                <Link to='/'>
                                    <img alt='分期商城' style={{ height: '100%', width: '100%' }} src='http://liaofushen.xyz:8081/logo.png' />
                                </Link>
                            </div>
                            <div className='cart-head-content-words'>支付</div>
                        </div>
                    </div>
                </div>
                <div style={{ textAlign: 'center', width: '300px', height: '300px', margin: '40px auto' }} className='pay-code-box'>
                    {
                        ajaxCompleted? price? <div style={{ fontSize: '30px', color: 'red' }}>{ `￥${price}` }</div>: '': ''
                    }
                    { 
                        ajaxCompleted? payCode? <img alt='支付二维码' style={{ width: '100%' }} src={ payCode } />: '': ''
                    }
                </div>
                <div className='pay-btns-box'>
                    <button className='pay-btn' onClick={ () => this.postPayRequest(1) }>我已支付</button>
                    <button className='pay-btn' onClick={ () => this.postPayRequest(0) }>取消支付</button>
                </div>
                <Footer />
            </div>
        );
    }
}
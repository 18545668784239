import React from 'react'
import axios from 'axios'
import Footer from '../../components/Footer'
import { Link } from 'react-router-dom'
import '../../css/common.css'
import '../../css/register.css'
export default class Register extends React.Component {
    constructor(props) {
        super(props);
        this.state = { registerSuccess: false, registerError: false, registerMessage: '', registerRequesting: false };
    }

    postRegisterRequest(username, password) {
        this.setState({ registerRequesting: true });
        axios.post('http://www.liaofushen.xyz/user/register', { username, password }).then(function(response) {
            console.log(response.data);
            let data = response.data;
            if (data.status === 0) {
                alert('注册成功，页面即将跳转。');
                window.location.href = '/#/';
            }
            else {
                this.setState({ registerError: true, registerMessage: data.message, registerRequesting: false });
            }
        }.bind(this)).catch(function(error) {
            this.setState({ registerError: true, registerMessage: '注册发生错误', registerRequesting: false });
            console.log(error);
        });
    }

    render() {
        let { registerSuccess, registerError, registerMessage, registerRequesting } = this.state;
        if (registerSuccess) {
            return null;
        }
        let usernameInput, passwordInput;
        return (
            <div id='content'>
                <div className='register-head'>
                    <div className='w'>
                        <div className='register-head-content'>
                            <div className='register-head-content-logo'>
                                <Link to='/'>
                                    <img alt='分期商城' style={{ height: '100%', width: '100%' }} src='http://liaofushen.xyz:8081/logo.png' />
                                </Link>
                            </div>
                            <div className='register-head-content-words'>欢迎注册</div>
                        </div>
                    </div>
                </div>
                <div className='w'>
                    <div className='register-form'>
                        <div className='form-item'>
                            <div className='form-title'>注册</div>
                        </div>
                        <div className='form-item'>
                            <span className='item-head'>账号</span>
                            <span className='item-content'>
                                <input ref={ node => usernameInput = node } className='form-input' placeholder='在此输入账号' type='text' />
                            </span>
                        </div>
                        <div className='form-item'>
                            <span className='item-head'>密码</span>
                            <span className='item-content'>
                                <input ref={ node => passwordInput = node } className='form-input' placeholder='在此输入密码' type='password' />
                            </span>
                        </div>
                        {
                            registerError ? <div className='form-item'><div className='register-form-error'>{ registerMessage }</div></div> : ''
                        }
                        <div className='form-item'>
                            <button 
                                className='form-register-btn' 
                                onClick={ () => {
                                    if (registerRequesting)
                                        return;
                                    this.postRegisterRequest(usernameInput.value, passwordInput.value)
                                }}
                            >注册</button>
                        </div>
                    </div>
                </div>
                <div className='w'>
                    <Footer />
                </div>
            </div>
        );
    }
}
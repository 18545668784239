import React from 'react'
import * as types from '../constants/ActionTypes'

const MsgStatus = { SENDING: 0, UNREAD: 1, READ: 2 };
const csInitialState = {
    wsReadyState: 0,
    wsLoggedIn: false,
    csRequesting: false,
    csRequestSuc: false,
    csRequestSucAndEnd: false,
    chattingWith: '',
    waitingCount: 0
};
const csStaffInfoInitial = { staffId: '', loggedIn: false };

export function csState(state = csInitialState, action) {
    switch(action.type) { // readyState: WebSocket.readyState
        // Common
        case types.WEB_SOCKET_READY_STATE_CHANGE:
            return { ...state, wsReadyState: action.readyState };
        // User
        case types.USER_WS_LOG_IN:
            return { ...state, wsLoggedIn: action.status };
        case types.USER_REQUEST_CS:
            return { ...state, csRequesting: action.status };
        case types.USER_CANCEL_REQUEST_CS:
            return { ...state, csRequesting: false };
        case types.REQUEST_CS_SUCCESS:
            return { ...state, csRequestSuc: true, csRequesting: false, chattingWith: action.staffInfo.staffId };
        case types.CHAT_ALREADY_EXISTS:
            return { ...state, csRequestSuc: true, csRequesting: false, chattingWith: action.staffInfo.staffId };
        case types.CS_CHAT_END:
            return { ...state, csRequestSuc: false, csRequestSucAndEnd: true };
        case types.USER_QUIT_CHAT:
            return { ...state, csRequestSucAndEnd: false };
        // Staff
        case types.STAFF_WS_LOG_IN:
            return { ...state, wsLoggedIn: action.status };
        case types.STAFF_CHATTING_WITH:
            return { ...state, chattingWith: action.chattingWith };
        case types.STAFF_CLOSE_CHAT_SUCCESS:
            return { ...state, chattingWith: '' }
        // waiting count
        case types.CS_WAITING_COUNT:
            return { ...state, waitingCount: action.waitingCount };
        default:
            return state;
    }
}

export function csStaffInfo(state = csStaffInfoInitial, action) {
    switch(action.type) {
        case types.STAFF_SET_INFO: // action: { type, staffInfo }
            return { ...action.staffInfo };
        case types.REQUEST_CS_SUCCESS: // action: { type, staffInfo, chatLog }
            return { ...action.staffInfo };
        default:
            return state;
    }
}

// chatLog: { senderId, msg } userInfo: { userId } staffInfo: { staffId }
export function chatState(state = {}, action) {
    switch(action.type) {
        case types.STAFF_ACCEPT_CUSTOMER: // action: { type, userInfo, chatLog }
        {
            let msgCount = 1, msgStart = 0, unreadCount = 1;
            let { userInfo, chatLog } = action;
            chatLog = { ...chatLog, msgStatus: MsgStatus.UNREAD, msgId: msgStart };
            state[userInfo.userId] = { userInfo, chatLog: [].concat(chatLog), msgCount, unreadCount };
            return { ...state };
        }
        case types.REQUEST_CS_SUCCESS: // action: { type, staffInfo, chatLog }
        {
            let msgCount = 1, msgStart = 0, unreadCount = 0;
            let { staffInfo, chatLog } = action;
            chatLog = { ...chatLog, msgStatus: MsgStatus.UNREAD, msgId: msgStart };
            state[staffInfo.staffId] = { staffInfo, chatLog: [].concat(chatLog), msgCount, unreadCount };
            return { ...state };
        }
        case types.CHAT_ALREADY_EXISTS: // action: { type, staffInfo, chatLogsList }; chatLogsList: [chatLog, ...]
        {
            let unreadCount = 0;
            let { staffInfo, chatLogsList } = action;
            chatLogsList = chatLogsList.map((item, index) => {
                return { ...item, msgStatus: MsgStatus.READ, msgId: index };
            }); 
            state[staffInfo.staffId] = { staffInfo, chatLog: chatLogsList, msgCount: chatLogsList.length, unreadCount: unreadCount }
            return { ...state };
        }
        case types.USER_SEND_MSG: // action: { type, targetId, msg, msgId, userId }
        {
            let { targetId, msg, msgId, userId } = action;
            let chatLog = { 
                senderId: userId, 
                msg: msg, 
                msgStatus: MsgStatus.SENDING, 
                msgId: msgId
            };
            state[targetId].chatLog = state[targetId].chatLog.concat(chatLog);
            state[targetId].msgCount++;
            return { ...state }
        }
        case types.USER_SEND_MSG_SUCCESS: // action: { type, msgId, staffId }
        {
            state[action.staffId].chatLog[action.msgId].msgStatus = MsgStatus.UNREAD;
            return { ...state };
        }
        case types.MSG_FROM_OWN: // action: { tpye, targetId, senderId, msg }
        {
            let { targetId, senderId, msg } = action;
            let chatLog = {
                senderId,
                msg,
                msgStatus: MsgStatus.UNREAD,
                msgId: state[targetId].msgCount
            }
            state[targetId].chatLog = state[targetId].chatLog.concat(chatLog);
            state[targetId].msgCount++;
            return { ...state };
        }
        case types.STAFF_SEND_MSG: // action: { type, targetId, msg, msgId, staffId }
        {
            let { targetId, msg, msgId, staffId } = action;
            let chatLog = { 
                senderId: staffId, 
                msg: msg, 
                msgStatus: MsgStatus.SENDING, 
                msgId: msgId
            };
            state[targetId].chatLog = state[targetId].chatLog.concat(chatLog);
            state[targetId].msgCount++;
            return { ...state }
        }
        case types.STAFF_SEND_MSG_SUCCESS: // action: { type, msgId, userId }
        {
            state[action.userId].chatLog[action.msgId].msgStatus = MsgStatus.UNREAD;
            return { ...state };
        }
        case types.STAFF_CLOSE_CHAT_SUCCESS: // action: { type, targetId }
        {
            delete state[action.targetId];
            return { ...state };
        }
        case types.RECEIVE_MSG: // action: { type, chatLog, flag }
        {
            let { chatLog, flag } = action;
            let senderId = chatLog.senderId;
            chatLog = { ...chatLog, msgId: state[senderId].msgCount };
            state[senderId].chatLog = state[senderId].chatLog.concat(chatLog);
            state[senderId].msgCount++;
            state[senderId].unreadCount += flag;
            return { ...state };
        }
        case types.READ_MSG: // action: { type, targetId }
        {
            let { targetId } = action;
            state[targetId].unreadCount = 0;
            return { ...state };
        }
        case types.MSG_GET_READ: // action: { type, senderId }
        {
            state[action.senderId].chatLog.map(
                chatLog => { 
                    if (chatLog.senderId !== action.senderId) {
                        chatLog.msgStatus = MsgStatus.READ;
                    }
                    return chatLog;
                }
            );
            return { ...state };
        }
        case types.SEND_PICTURE_INFO_SUCCESS: // action: { type, senderId, targetId, dataUrl, msgId }
        {
            let { senderId, targetId, dataUrl, msgId } = action;
            let imgDom = React.createElement('img', { src: dataUrl, className: 'chat-img' }, null);
            let chatLog = { senderId, msg: imgDom, msgStatus: MsgStatus.SENDING }
            chatLog = { ...chatLog, msgId };
            state[targetId].chatLog = state[targetId].chatLog.concat(chatLog);
            state[targetId].msgCount++;
            return { ...state };
        }
        case types.SEND_PICTURE_END_SUCCESS: // action: { type, targetId, msgId }
        {
            let { targetId, msgId } = action;
            state[targetId].chatLog[msgId].msgStatus = MsgStatus.UNREAD;
            return { ...state };
        }
        case types.DOWNLOAD_PICTURE_COMPLETE: // action: { type, targetId, dataUrl, replaceText }
        {
            let { targetId, dataUrl, replaceText } = action;
            let imgDom = React.createElement('img', { src: dataUrl, className: 'chat-img' }, null);
            state[targetId].chatLog.map((obj) => {
                if (obj.msg === replaceText) {
                    obj.msg = imgDom;
                }
                return { ...obj };
            });
            return { ...state };
        }
        default:
            return state;
    }
}
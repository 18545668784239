import React from 'react'
import { connect } from 'react-redux'
import '../../css/cs.css'
import ContactBox from './ContactBox'
import StaffMsgBox from './StaffMsgBox'
import { startWebSocket, sendStaffAcceptCustomer } from '../../websocket-client'
import Footer from '../../components/Footer'
import '../../css/common.css'
import '../../css/order.css'
import '../../css/register.css'

class StaffChatBox extends React.Component {

    render () {
        let { chattingWith } = this.props.csState;
        let { sendStaffAcceptCustomer } = this.props;
        return (
            <div id='content'>
                <div className='order-head'>
                    <div className='w'>
                        <div className='order-head-content'>
                            <div className='order-head-content-logo'>
                                <img alt='分期商城' style={{ height: '100%', width: '100%' }} src='http://liaofushen.xyz:8081/logo.png' />
                            </div>
                            <div className='order-head-content-words'>客服工作页面</div>
                        </div>
                    </div>
                </div>
                <div className="staffChatBox clearfix">
                    <button 
                        onClick={ sendStaffAcceptCustomer }
                        className="cs-send-btn" 
                        style={{ margin: '10px' }}
                        id="getCustomerBtn">接收客户请求</button>
                    <button 
                        className="cs-send-btn" 
                        style={{ margin: '10px' }} 
                        id="logoutBtn">退出</button><br/>
                    <ContactBox />
                    { chattingWith === '' ? <div className='staffMsgBox'></div> : <StaffMsgBox /> }
                    <div className="customServiceBox"></div>
                </div>
                <Footer />
            </div>
        );
    }
}

const mapStateToProps = state => ({
    csState: state.csState
});

const mapDispatchToProps = dispatch => ({
    wsStart: () => startWebSocket(true),
    sendStaffAcceptCustomer: () => sendStaffAcceptCustomer()
});

export default connect(mapStateToProps, mapDispatchToProps)(StaffChatBox);
import React from 'react'
import '../../css/common.css'
import '../../css/commodity.css'
import axios from 'axios'
import { Link } from 'react-router-dom'
export default class CommodityIntro extends React.Component {
    constructor(props) {
        super(props);
        this.state = { isInstallment: false, choseInstallmentIndex: -1, buyCount: 1 };
    }

    installmentItemClick(index) {
        let { choseInstallmentIndex } = this.state;
        if (index === choseInstallmentIndex) {
            this.setState({ isInstallment: false, choseInstallmentIndex: -1 });
        }
        else {
            this.setState({ isInstallment: true, choseInstallmentIndex: index });
        }
    }

    weightConverter(weight) {
        if (weight > 500000) {
            return `${weight / 1000000}t`;
        }
        else if (weight > 500) {
            return `${weight / 1000}kg`;
        }
        else {
            return `${weight}g`;
        }
    }

    buyCountChange(buyCount) {
        buyCount = buyCount + '';
        buyCount = buyCount.replace(/[^0-9]/ig, '');
        if (!buyCount) {
            buyCount = 1;
        }
        buyCount = parseInt(buyCount);
        if (buyCount === 0) {
            buyCount = 1;
        }
        let num = this.props.commodityIntro.commodity.num;
        if (buyCount > num) {
            buyCount = num;
        }
        this.setState({ buyCount });
    }

    addToCart(id, num) {
        axios.post('http://www.liaofushen.xyz/cart', { id, num }).then((response) => {
            console.log(response.data);
            if (response.data.status === 0) {
                window.location.href = '/#/cart';
            }
        }).catch(function (error) {
            console.log(error);
        });
    }

    render() {
        let { isInstallment, choseInstallmentIndex, buyCount } = this.state;
        let { commodity, installment } = this.props.commodityIntro;
        let buyCountInput;
        return (
            <div className='commodity-intro'>
                <div className='preview-wrap'>
                    <img alt='商品图片' className='preview-img' src={ commodity.image } />
                </div>
                <div className='itemInfo-wrap'>
                    <div className='itemInfo-title'>{ commodity.title }</div>
                    <div className='itemInfo-setPoint'>{ commodity.sellPoint }</div>
                    <div className='itemInfo-priceBox'>
                        <span className='itemInfo-head'>价格</span><span className='itemInfo-price'>￥{ commodity.price }</span>
                    </div>
                    <div className='itemInfo-box'>
                        <div className='itemInfo-head'>库存</div><span className='itemInfo-content'>{ commodity.num }</span>
                    </div>
                    <div className='itemInfo-box'>
                        <div className='itemInfo-head'>尺寸</div><span className='itemInfo-content'>{ commodity.size }</span>
                    </div>
                    <div className='itemInfo-box'>
                        <div className='itemInfo-head'>颜色</div><span className='itemInfo-content'>{ commodity.colour }</span>
                    </div>
                    <div className='itemInfo-box'>
                        <div className='itemInfo-head'>重量</div><span className='itemInfo-content'>{ this.weightConverter(commodity.weight) }</span>
                    </div>
                    <div className='itemInfo-ins'>
                        <div className='itemInfo-head'>分期付款</div>
                        {
                            installment.map((item, index) => {
                                return (
                                    <span 
                                        className={ `ins-item ${ choseInstallmentIndex === index ? 'ins-item-checking': '' }`} 
                                        key={ index }
                                        onClick={ () => this.installmentItemClick(index) }
                                    >{ `￥${(item.perPrice * buyCount).toFixed(2)}起×${item.times}期` }</span>
                                );
                            })
                        }
                    </div>
                    <div className='split-line'></div>
                    <div className='itemInfo-btnBox'>
                        <div className='buyCount-box'>
                            <div className='buyCount-count'>
                                <input 
                                    ref={ node => buyCountInput = node } 
                                    className='buyCount-count-input' type='text' value={ buyCount } onChange={ () => {
                                    this.buyCountChange(buyCountInput.value);
                                }} />
                            </div>
                            <div className='buyCount-operation'>
                                <div className={ `buyCount-add ${ buyCount < commodity.num ? '': 'unclickable' }` } onClick={ () => {
                                    if (buyCount < commodity.num) {
                                        this.buyCountChange(++buyCount);
                                    }
                                }} >+</div>
                                <div className={ `buyCount-subtract ${ buyCount > 1 ? '': 'unclickable'}` } onClick={ () => {
                                    if (buyCount > 1) {
                                        this.buyCountChange(--buyCount);
                                    }
                                }}>-</div>
                            </div>
                        </div>
                        {
                            isInstallment ? 
                                <span>
                                    <Link to={ `/insterestOrder/${commodity.id}/${buyCount}/${choseInstallmentIndex}` }>
                                        <div className='itemInfo-btn'>购买</div>
                                    </Link>
                                    <span className='buy-notice'>注：使用分期付款时只能直接购买</span>
                                </span> : 
                                <div className='itemInfo-btn'
                                    onClick={ () => this.addToCart(commodity.id, buyCount) }
                                >加入购物车</div>
                        }
                    </div>
                </div>
            </div>
        );
    }
}
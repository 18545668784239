import React from 'react'
import { Link } from 'react-router-dom'
import '../../css/common.css'
import '../../css/search.css'
export default class CommoditiesBox extends React.Component {
    render() {
        let { commodities } = this.props;
        return (
            <div className='commodities-content'>
                {
                    commodities.map((item) => {
                        return (
                            <Link to={`/commodity/${item.id}`} key={item.id}>
                                <div className='commodity-box'>
                                    <img alt={ item.title } className='commodity-img' src={item.image} />
                                    <div className='commodity-price'>{ `￥${item.price}` }</div>
                                    <div className='commodity-title' title={ item.title }>{ item.title }</div>
                                </div>
                            </Link>
                        );
                    })
                }
            </div>
        );
    }
}
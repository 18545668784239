import React from 'react'
import { Link } from 'react-router-dom'
import '../../css/common.css'
import '../../css/search.css'
import '../../css/recommend.css'
import '../../css/home.css'
export default class SingleRecommendBox extends React.Component {
    constructor(props) {
        super(props);
        this.state = { showingIndex: 0 };
    }

    changeShowingIndex(index) {
        this.setState({ showingIndex: index });
    }

    render() {
        let { showingIndex } = this.state;
        let { commoditiesList, recommendReason } = this.props;
        return (
            <div style={{ width: '1000px', overflow: 'hidden', marginTop: '30px' }}>
                <h4 style={{ marginBottom: '10px' }}>{ recommendReason }</h4>
                <hr style={{ borderWidth: '2px' }} />
                <div 
                    className='rb-content' 
                    style={{ 
                        width: `${commoditiesList.length * 1000}px`, 
                        height: '315px',
                        overflow: 'hidden', 
                        transform: 'translate3d(0, 0, 0)' 
                    }}
                >
                    {
                        commoditiesList.map((item, index) => {
                            let isShowing = showingIndex === index;
                            return (
                                <div 
                                    className='rb-c-box' 
                                    key={ index }
                                    style={{
                                        zIndex: isShowing ? '1000': '999',
                                        opacity: isShowing ? '1': '0',
                                        transition: 'opacity 500ms ease-in-out',
                                        float: 'left',
                                        position: 'relative',
                                        width: '1000px',
                                        left: '-' + index * 1000 + 'px'
                                    }}
                                >
                                    {
                                        item.map((item1, index1) => {
                                            return (
                                                <Link to={`/commodity/${item1.id}`} key={ index1 }>
                                                    <div className='commodity-box'>
                                                        <img alt={ item1.title } className='commodity-img' src={item1.image} />
                                                        <div className='commodity-price'>{ `￥${item1.price}` }</div>
                                                        <div className='commodity-title' title={ item1.title }>{ item1.title }</div>
                                                    </div>
                                                </Link>
                                            );
                                        })
                                    }
                                </div>
                            )
                        })
                    }
                    <ul className='slider-dots'>
                        {
                            commoditiesList.length > 1? commoditiesList.map((item, index) => {
                                return (
                                    <li 
                                        className={ `slider-dot ${showingIndex === index ? 'slider-dot-check': ''}` } 
                                        key={ index } 
                                        onMouseOver={ () => this.changeShowingIndex(index) }
                                    ></li>
                                );
                            }): ''
                        }
                    </ul>
                </div>
            </div>
        )
    }
}
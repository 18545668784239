import * as types from '../constants/ActionTypes'
import { sendStaffSendMsg, sendUserSendMsg, sendMsgGetRead } from '../websocket-client'
//import { getStoreDispatch } from '../store'

//const MsgState = { SENDING: 0, UNREAD: 1, READ: 2 };

export function webSocketReadyStateChange(readyState) {
    return {
        type: types.WEB_SOCKET_READY_STATE_CHANGE,
        readyState
    }
}

export function requestCSSuccess(data) {
    return {
        type: types.REQUEST_CS_SUCCESS,
        data
    }
}

////////////////////// chatState /////////////////////

export function pushChatLog(data) {
    return {
        type: types.PUSH_CHAT_LOG,
        data
    }
}

export function userSendMsgSuccess(data) {
    return {
        type: types.USER_SEND_MSG_SUCCESS,
        data
    }
}

export function msgGetRead(data) { 
    return {
        type: types.MSG_GET_READ,
        data
    }
}

export function getCustomerSuccess(data) {
    return {
        type: types.GET_CUSTOMER_SUCCESS,
        data
    }
}

export function userWSLogIn(data) {
    return {
        type: types.USER_WS_LOG_IN,
        data
    }
}

export function staffChattingWith(chattingWith) {
    return {
        type: types.STAFF_CHATTING_WITH,
        chattingWith
    }
}

export function userSendMsg(targetId, msg, msgId, userId) {
    sendUserSendMsg(targetId, msg, msgId);
    return {
        type: types.USER_SEND_MSG,
        targetId,
        msg,
        msgId,
        userId
    }
}

export function staffSendMsg(targetId, msg, msgId, staffId) {
    sendStaffSendMsg(targetId, msg, msgId);
    return {
        type: types.STAFF_SEND_MSG,
        targetId,
        msg,
        msgId,
        staffId
    }
}

export function userQuitChat() {
    return {
        type: types.USER_QUIT_CHAT
    }
}

export function receiveMsg(action, chattingWith) {
    let { chatLog } = action;
    let senderId = chatLog.senderId;
    let flag = senderId === chattingWith ? 0 : 1;
    if (chattingWith === senderId) {
        sendMsgGetRead(chattingWith);
    }
    return { ...action, flag }
}

export function readMsg(targetId) {
    return {
        type: types.READ_MSG,
        targetId
    }
}

export function staffSetInfo(staffInfo) {
    return {
        type: types.STAFF_SET_INFO,
        staffInfo
    }
}
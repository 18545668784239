import React from 'react'
import '../../css/common.css'
import '../../css/search.css'
import SearchBox from '../../components/SearchBox'
import CommoditiesBox from './CommoditiesBox'
import Footer from '../../components/Footer'
import Header from '../../components/Header'
export default class Search extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {
        let search = this.props.location.search.split('?')[1];
        if (search) {
            let reg = new RegExp('(^|&)keyword=([^&]*)(&|$)', 'i');
            if (!reg.test(search)) {
                this.setState({ searchResult: [], defaultText: '' });
                return;
            }
            let keywork = search.match(reg)[2];
            const apiUrl = 'http://www.liaofushen.xyz/category?keyword=' + keywork;
            fetch(apiUrl).then((response) => {
                if (response.ok) {
                    return response.json();
                }
                else {
                    return Promise.reject('wrong.');
                }
            }).then((data) => {
                this.setState({ searchResult: data.data, defaultText: decodeURI(keywork) });
                console.log(data.data);
            }).catch((error) => {
                this.setState({ searchResult: [], defaultText: '' });
                console.log(error);
            });
        }
        else {
            this.setState({ searchResult: [] });
        }
    }

    render() {
        if (!('searchResult' in this.state)) {
            return null;
        }
        let { searchResult, defaultText } = this.state;
        let show;
        if (searchResult.length === 0) {
            show = <div className='no-result-box'>很抱歉，没有找到符合的商品。</div>;
        }
        else {
            show = <CommoditiesBox commodities={ searchResult } />;
        }
        return (
            <div id='content'>
                <Header />
                <div className='w'>
                    <SearchBox defaultValue={ defaultText } />
                    { show }
                </div>
                <Footer />
            </div>
        );
    }
}
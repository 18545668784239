import React from 'react'
import { Link } from 'react-router-dom'
import '../../css/home.css'
import '../../css/common.css'
import MyCart from './MyCart'
export default class SearchBox extends React.Component {

    constructor(props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {
        let { defaultValue } = this.props;
        this.setState({ inputValue: defaultValue });
    }

    searchCommodities(searchText) {
        window.location.href = `/#/search?keyword=${searchText}`;
    }

    inputValueChange(changeValue) {
        this.setState({ inputValue: changeValue });
    }

    render() {
        let searchInput;
        if (!('inputValue' in this.state)) {
            return null;
        }
        let { inputValue } = this.state;
        return (
            <div className='search-box'>
                <div className='search-logo'>
                    <Link to={ `/` }><img alt='分期商城' style={{ height: '100%', width: '100%' }} src='http://liaofushen.xyz:8081/logo.png' /></Link>
                </div>
                <input 
                    ref={ node => searchInput = node } 
                    className='search-input' 
                    type='text' 
                    placeholder='在此输入商品名称'
                    value={ inputValue }
                    onChange={ () => this.inputValueChange(searchInput.value) }
                />
                <button className='search-btn' onClick={ () => this.searchCommodities(searchInput.value) } >搜索</button>
                <MyCart />
            </div>
        );
    }
}
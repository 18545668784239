import React from 'react'
import { connect } from 'react-redux'
import '../../css/cs.css'
import StaffChatBox from './StaffChatBox'
import StaffLogIn from './StaffLogIn'

class CSStaff extends React.Component {
    render () {
        let { wsLoggedIn } = this.props.csState;
        return (
            <div>
                { wsLoggedIn ? <StaffChatBox /> : <StaffLogIn /> }
            </div>
        );
    }
}

const mapStateToProps = state => ({
    csState: state.csState
});

const mapDispatchToProps = dispatch => ({
    
});

export default connect(mapStateToProps, mapDispatchToProps)(CSStaff);